import { Redirect } from 'react-router-dom'
import Home from '@/pages/Home'
import Profile from '@/pages/Profile'

const Router = [
  {
    path: '/',
    exact: true,
    render() {
      return <Redirect to="/home" />
    }
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/profile/:key',
    exact: true,
    component: Profile,
  }
]

export default Router
