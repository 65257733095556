import styled from 'styled-components'

export const TopBarWrapper = styled.div`
  width: 100%;
  height: 108px;
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  .topbarText {
    max-width: 1330px;
    width: 1330px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 228px;
      height: 49px;
    }
    .textRigth {
      display: flex;
      align-items: center;
      .road {
        width: 217px;
        height: 34px;
        margin-right: 41px;
        cursor: pointer;
      }
      .profile {
        width: 120px;
        height: 34px;
        margin-right: 41px;
        cursor: pointer;
      }
    }
    @media (max-width: 768px) {
      max-width: 1800px;
      width: 1800px;
      .logo {
        width: 456px;
        height: 98px;
      }
      .textRigth {
        .road {
          width: 318px;
          height: 68px;
          margin-right: 82px;
        }
        .profile {
          width: 240px;
          height: 68px;
          margin-right: 82px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: 300px;
  }
`
