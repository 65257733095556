import React, { memo } from 'react'
import styled from 'styled-components'
import routes from '@/router'
import { renderRoutes } from 'react-router-config'
import Web3Provider from '@/components/Web3Provider'

const AppWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgba(16, 72, 255, 0.15), rgba(243, 245, 249, 0.65));
  .appTips {
    width: 100%;
    padding: 100px 20px;
    text-align: center;
    font-size: 24px;
    .en {
      font-size: 16px;
    }
  }
`

export default memo(function App() {
  return (
    <Web3Provider>
      <AppWrapper>{renderRoutes(routes)}</AppWrapper>
    </Web3Provider>
  )
})
