import { message, notification } from 'antd'
import axios from 'axios'
import { appId, signSha, timeStamp } from '.';
/**
 * Create Axios instance
 * axios中请求配置有baseURL选项，表示请求URL公共部分
 * timeout  超时时间 默认5秒
 */

const Http = axios.create({
  baseURL: 'https://recharge.mtssport.io/v1/api/', // http://47.108.77.85:11002  // https://recharge.mtssport.io
  timeout: 50000,
})

Http.interceptors.request.use(
  (config: any) => {
    // if (config) {
    //   let token = sessionStorage.getItem('token');
    //   token && (config.headers["access-token"] = token);
    //   config.headers["appId"] = appId
    //   config.headers["timestamp"] = timeStamp()
    //   config.headers["sign"] = signSha()
    // }
    return config
  },
  (error: any) => Promise.reject(error)
)

Http.interceptors.response.use(
  (config: any) => {
    return config
  },
  (error: any) => Promise.reject(error)
)

export default Http
