import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import enUsTrans from '@/locales/en-us'
import zhCnTrans from '@/locales/zh-cn'
import vieTrans from '@/locales/vie'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: enUsTrans,
  },
  zh: {
    translation: zhCnTrans,
  },

  vie: {
    translation: vieTrans,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'zh-CN',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
export default i18n
