import styled from 'styled-components'
import Table from '@/assets/Img/teable.png'

export const RewardWral = styled.div`
  width: 100%;
  margin-top: 520px;
  .img-rew {
    display: flex;
    width: 993px;
    height: 62px;
    margin: 60px auto 0;
  }
  .textstyle {
    width: 1200px;
    margin: 20px auto;
    text-align: center;
    font-size: 24px;
    .title {
      color: #00dbf9;
      font-weight: bold;
    }
    p {
      color: #ffffff;
      font-weight: normal;
      color: #ffffff;
      line-height: 36px;
      font-family: AvenirNext-DemiBoldItalic;
    }
  }
  .tableStyle {
    margin: auto;
    background-image: url('${Table}'); // no-repeat;
    width: 1240px;
    height: 1124px;
    padding: 40px 10px 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    .table-title {
      position: relative;
      width: 1202px;
      height: 101px;
    }
    .table {
      margin: auto;
      width: 97.6%;
      overflow-y: auto;
      height: 950px;
      .key-table {
        font-size: 24px;
        font-family: AvenirNextBold;
        font-weight: bold;
      }
      .data-table {
        font-family: AvenirNextBold;
        color: #00e2ff;
        font-size: 24px;
        font-weight: 800;
        &.data-table-second {
          font-family: AvenirNext-DemiBold;
        }
        &.data-table-third {
          font-family: AvenirNext-Medium;
          font-weight: 500;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1280px;
    .img-rew {
      transform: scale(2);
      margin-bottom: 120px;
    }
    .textstyle {
      height: unset;
      line-height: 120px;
      font-size: 60px;
      width: 1800px;
      p {
        line-height: unset;
      }
    }
    .tableStyle {
      font-size: 0;
      height: unset;
      width: 1920px;
      padding-bottom: 50px;
      .table-title {
        height: unset;
        width: 1920px;
      }
      .table {
        height: 1600px;
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 20px;
        }
        .key-table {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
`
