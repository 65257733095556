import { Carousel } from 'antd'
import { memo } from 'react'
import { CarouselWral } from './styled'
import _star5_1 from '@/assets/NFT/1.png'
import _star5_2 from '@/assets/NFT/2.png'
import _star5_3 from '@/assets/NFT/3.png'
import _star5_4 from '@/assets/NFT/4.png'
import _star5_5 from '@/assets/NFT/5.png'
import _star5_6 from '@/assets/NFT/6.png'
import _star5_7 from '@/assets/NFT/7.png'

import _star4_1 from '@/assets/NFT/8.png'
import _star4_2 from '@/assets/NFT/9.png'
import _star4_3 from '@/assets/NFT/10.png'
import _star4_4 from '@/assets/NFT/11.png'
import _star4_5 from '@/assets/NFT/12.png'
import _star4_6 from '@/assets/NFT/13.png'
import _star4_7 from '@/assets/NFT/14.png'

import _star3_1 from '@/assets/NFT/15.png'
import _star3_2 from '@/assets/NFT/16.png'
import _star3_3 from '@/assets/NFT/17.png'
import _star3_4 from '@/assets/NFT/18.png'
import _star3_5 from '@/assets/NFT/19.png'
import _star3_6 from '@/assets/NFT/20.png'
import _star3_7 from '@/assets/NFT/21.png'
import _star3_8 from '@/assets/NFT/22.png'
import _star3_9 from '@/assets/NFT/23.png'
import _star3_10 from '@/assets/NFT/24.png'
import _star3_11 from '@/assets/NFT/25.png'
import _star3_12 from '@/assets/NFT/26.png'
import _star3_13 from '@/assets/NFT/27.png'
import _star3_14 from '@/assets/NFT/28.png'
import _star3_15 from '@/assets/NFT/29.png'
import _star3_16 from '@/assets/NFT/30.png'
import _star3_17 from '@/assets/NFT/21.png'
import _star3_18 from '@/assets/NFT/32.png'

export default memo(function CarouselPage(props: any) {
  return (
    <CarouselWral>
      <Carousel autoplay className="carousel">
        <div>
          <div className="BannerStyle">
            <img src={_star5_1} alt="" />
            <img src={_star5_2} alt="" />
            <img src={_star5_3} alt="" />
            <img src={_star5_4} alt="" />
            <img src={_star5_5} alt="" />
            <img src={_star5_6} alt="" />
            <img src={_star5_7} alt="" />
            <img src={_star4_1} alt="" />
            <img src={_star4_2} alt="" />
            <img src={_star4_3} alt="" />
            <img src={_star4_4} alt="" />
            <img src={_star4_5} alt="" />
            <img src={_star4_6} alt="" />
            <img src={_star4_7} alt="" />
          </div>
        </div>
        <div>
          <div className="BannerStyle">
            <img src={_star3_1} alt="" />
            <img src={_star3_2} alt="" />
            <img src={_star3_3} alt="" />
            <img src={_star3_4} alt="" />
            <img src={_star3_5} alt="" />
            <img src={_star3_6} alt="" />
            <img src={_star3_7} alt="" />
            <img src={_star3_8} alt="" />
            <img src={_star3_9} alt="" />
            <img src={_star3_10} alt="" />
            <img src={_star3_11} alt="" />
            <img src={_star3_12} alt="" />
            <img src={_star3_13} alt="" />
            <img src={_star3_14} alt="" />
          </div>
        </div>
        <div>
          <div className="BannerStyle">
            <img src={_star3_15} alt="" />
            <img src={_star3_16} alt="" />
            <img src={_star3_17} alt="" />
            <img src={_star3_18} alt="" />
          </div>
        </div>
      </Carousel>
    </CarouselWral>
  )
})
