import BNB_ICON from '@/assets/token/BNB.svg'
import ETH_ICON from '@/assets/token/ETH.svg'
import FINDORA_ICON from '@/assets/token/findora.svg'
import METIS_ICON from '@/assets/token/metis.svg'
import METAMASK_ICON from '@/assets/svg/metamask.svg'
import WALLET_CONNECT_ICON from '@/assets/svg/wallet-connect.svg'
import BNB_MIN from '@/assets/token/Binance-min.svg'
import ETH_MIN from '@/assets/token/Ethereum-min.svg'
import METIS_MIN from '@/assets/token/metis-min.svg'
import FINDORA_MIN from '@/assets/token/findora-min.svg'
import { ImageError } from '@/utils'

export interface listTypes {
  name: string
  icon: string
  chainId: any
  backgroundImage: string
  img: string
  fullName: string
}

export const NetWorkObj: any = {
  prd: [
    {
      name: 'Ethereum',
      fullName: 'Ethereum Mainnet',
      icon: ETH_ICON,
      img: ETH_MIN,
      chainId: 1,
      backgroundImage: 'linear-gradient(to right,#495EFC,#3F84EE)',
    },
    {
      name: 'BNB Chain',
      fullName: 'Binance Smart Chain',
      icon: BNB_ICON,
      img: BNB_MIN,
      chainId: 56,
      backgroundImage: 'linear-gradient(to right,#3E3F47,#525961)',
    },
    {
      name: 'Metis',
      fullName: 'Metis Andromeda Mainnet',
      img: METIS_ICON,
      icon: METIS_MIN,
      backgroundImage: 'linear-gradient(73.28deg, #012033 6.51%, #012033 88.45%)',
      chainId: 1088,
    },
    {
      name: 'Findora',
      fullName: 'Findora Mainnet',
      img: FINDORA_ICON,
      icon: FINDORA_MIN,
      backgroundImage: 'linear-gradient(73.28deg, #8247E5 6.51%, #6027C0 88.45%)',
      chainId: 2152,
    },
  ],
  dev: [
    {
      name: 'Kovan',
      fullName: 'Kovan Testnet',
      icon: ETH_ICON,
      img: ETH_MIN,
      chainId: 42,
      backgroundImage: 'linear-gradient(to right,#495EFC,#3F84EE)',
    },
    {
      name: 'Binance',
      fullName: 'BNB Smart Chain Testnet',
      icon: BNB_ICON,
      img: BNB_MIN,
      chainId: 97,
      backgroundImage: 'linear-gradient(to right,#3E3F47,#525961)',
    },
    {
      name: 'Test',
      fullName: '47 Test NetWork',
      icon: ImageError,
      img: ImageError,
      chainId: 1337,
      backgroundImage: 'linear-gradient(73.28deg, #012033 6.51%, #012033 88.45%)',
    },
  ],
  uat: [
    {
      name: 'Kovan',
      fullName: 'Kovan Testnet',
      icon: ETH_ICON,
      img: ETH_MIN,
      chainId: 42,
      backgroundImage: 'linear-gradient(to right,#495EFC,#3F84EE)',
    },
    {
      name: 'Binance',
      fullName: 'BNB Smart Chain Testnet',
      icon: BNB_ICON,
      img: BNB_MIN,
      chainId: 97,
      backgroundImage: 'linear-gradient(to right,#3E3F47,#525961)',
    },
    {
      name: 'Metis',
      fullName: 'Metis Stardust Testnet',
      img: METIS_ICON,
      icon: METIS_MIN,
      backgroundImage: 'linear-gradient(73.28deg, #012033 6.51%, #012033 88.45%)',
      chainId: 588,
    },
    {
      name: 'Findora',
      fullName: 'Findora Testnet',
      img: FINDORA_ICON,
      icon: FINDORA_MIN,
      backgroundImage: 'linear-gradient(73.28deg, #8247E5 6.51%, #6027C0 88.45%)',
      chainId: 2153,
    },
  ],
}

export const walletInit: { name: string; icon: string; link: string }[] = [
  {
    name: 'Metamask',
    link: 'Injected',
    icon: METAMASK_ICON,
  },
  {
    name: 'Binance Wallet',
    link: 'bsc',
    icon: BNB_MIN,
  },
  {
    name: 'WalletConnect',
    link: 'WalletConnect',
    icon: WALLET_CONNECT_ICON,
  },
]

