import axios from 'axios'

export const readGetPastEventsBSC = (avaxUrl: string, address: string, topic0: any, apikey: string) => {
    return new Promise((reslove, reject) => {
        axios.get(avaxUrl, {
            params: {
                module: 'logs',
                action: 'getLogs',
                fromBlock: 0,
                address,
                topic0,
                apikey
            }
        }).then((data: any) => {
            let result = data.result
            if (result !== undefined && result instanceof Array) {
                reslove(result)
            } else {
                reslove([])
            }
        }).catch((err: any) => {
            reject(err)
        })
    })
}
export const readGetPastEvents = (contract: any, events: string, blockNumber: number, filter?: object) => {
    return new Promise((reslove, reject) => {
        contract.getPastEvents(events, { fromBlock: blockNumber, toBlock: 'latest', filter: { ...filter } }, function (error: any, event: any) {
            if (event instanceof Array) {
                reslove({ data: event, error: null })
            } else {
                // console.log('events', events)
                // console.log('error', error)
                reslove({ data: [], error })
            }
        })
    })
}