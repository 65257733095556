import { Button, Carousel, Col, Form, Input, InputNumber, message, Modal, Radio, Row } from 'antd'
import { createRef, memo, useEffect, useRef, useState } from 'react'
import { SoldOutWral, BlindBoxWrarl, BtnSucceed, BtnText, SucWrarl, TokenWral } from './styled'
import Usdt from '@/assets/Img/usdt.png'
import Mts from '@/assets/Img/mts.png'
import Copy_icon from '@/assets/Img/copy.png'
import wh_icon from '@/assets/Img/cardW.png'
import total from '@/assets/Img/total.png'
import cardImg from '@/assets/Img/card.png'
import boxBom from '@/assets/Img/boxBom.png'
import jia from '@/assets/Img/jia.png'
import jian from '@/assets/Img/jian.png'
import close from '@/assets/Img/close.png'
import clane from '@/assets/Img/clane.png'
import Copyto from "copy-to-clipboard"
import { WorldType, useWorldCupCardHooks } from "@/hooks/useWorldCupCardHooks";
import useDataHooks from "@/hooks/useDataHooks";
import { ConstantInitTypes } from "@/contracts/constantInit";
import Loading from "../Loading";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";
import { appId, ation, fromWei, GetRequest, ipConfig, signSha, transFiatCurrency } from "@/utils";
import NumberRoll from '../NumberRoll'
import { useMarketHooks } from '@/hooks/useMarketHooks'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Http from '@/utils/request'
import { web } from 'webpack'
import web3 from '@/contracts/initWeb3'

interface Type {
    value: any
}

const boxDefault = require('@/assets/Mp4/boxDefault.mp4')

let sucNumOuy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export default memo(function SoldOutPage(props: any) {
    const dataInit: ConstantInitTypes = useDataHooks()
    const { constant, USDTToken_Address, MTSToken_Address, Token_ADDRESS, Market_Address, BlindBox_Address } = dataInit;
    const context = useWeb3React<Web3Provider>()
    const { account } = context;
    const walletInfo = useSelector((state: any) => state.walletInfo)
    const myAddress = useSelector((state: any) => state.userInfo.address)
    const userAdress = walletInfo.wallet === 'Bsc' ? myAddress : account;
    const dataInt: any = useMarketHooks()
    const share = dataInt;

    const [boxShow, setBoxShow] = useState(false)
    const [sucShow, setSucShow] = useState(false)

    const [boxType, setBoxType] = useState<string>()
    const [loading, setLoading] = useState(false);
    const [boxNum, setBoxNum] = useState<any>(1);
    const [listBox, setlistBox] = useState<any>(1);
    const [Jine, setJine] = useState(10)
    const [usdtBalance, setUsdtBalance] = useState(0)
    const [mtsBalance, setMtsBalance] = useState(0)
    const [bnbBalance, setBnbBalance] = useState<string>()
    const [sucNum, setSucNum] = useState<any>([]);
    const address = `${(ipConfig(ation))}?address=${userAdress}`
    const numberRegExpS = "^([1-9]{1}[0-9]{0,7})$"
    const [solfDataInfo, setSolfDataInfo] = useState<WorldType>({
        total: 0,
        current: 0,
        available: 0,
        share: 0,
        winner: 0
    })
    const [queryTeam, setQueryTeam] = useState(false);
    const [mentType, setMentType] = useState<string>('2');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emaildata, setEmailData] = useState("");
    const [form] = Form.useForm();
    const [fiatData, setFiatData] = useState<any>([])
    const [daibi, setDaibi] = useState<string>('VND')
    const [numAmount, setNumAmount] = useState<number>(0)
    const [conversionScale, setConversionScale] = useState<number>(0)

    let history = useHistory()

    useEffect(() => {
        if (bnbBalance && daibi) {
            monitorToken()
        }

    }, [daibi, bnbBalance])

    const monitorToken = () => {
        console.log(12)
        switch (daibi) {
            case 'USD':
                form.setFieldsValue({ amount: (fiatData[1]?.payMin * 1.04).toFixed(8) })
                break;
            case 'THB':
                form.setFieldsValue({ amount: (fiatData[3]?.payMin * 1.04).toFixed(0) })
                break;
            case 'VND':
                form.setFieldsValue({ amount: (fiatData[2]?.payMin * 1.04).toFixed(0) })
                break;
        }
        setTimeout(() => {
            console.log(13)
            getConversionScale()
        }, 300);
        // console.log(form.getFieldsValue())
        if (!form.getFieldsValue()) {
            if (!form.getFieldsValue().email) {
                getConversionScale()
            }
        }
    }

    useEffect(() => {
        getFiatList()
    }, [])

    useEffect(() => {
        console.log(bnbBalance)
        if (bnbBalance) {
            setBnbBalance(bnbBalance)
        }
        // if (daibi && bnbBalance) {
        //     console.log('sdfghj')
        //     getConversionScale()
        // }
    }, [bnbBalance, daibi])

    useEffect(() => {
        if (userAdress) {
            boxTotal()
        }
    }, [userAdress])

    // 获取盲盒总数
    const boxTotal = async () => {
        setLoading(true)
        const max_CARDS = await constant.BlindBoxContract.methods.MAX_CARDS().call() // 已购买数量
        const total_Minted = await constant.BlindBoxContract.methods.totalMinted().call() // 盲盒总数
        const share = await constant.MarketContract.methods.share(userAdress).call() //领取奖励
        const winner = await constant.MarketContract.methods.winner().call() // 未设置冠军球队
        const team = await constant.MarketContract.methods.winnerReward(userAdress).call() // 查询是否领取过冠军奖励
        const mentType = await constant.MarketContract.methods.paymentType().call() // 查询盲盒购买权限 0:USDT 1:MTS 2:Both
        setQueryTeam(team)
        setMentType(mentType)
        if (max_CARDS && total_Minted) {
            setSolfDataInfo(
                {
                    total: max_CARDS,
                    current: total_Minted,
                    available: max_CARDS - total_Minted,
                    share: Number(fromWei(share)),
                    winner: winner
                })
            setLoading(false)
        }
    }
    // 查询是否授权
    const allowance = async () => {
        setLoading(true)
        const BalanceOf = await web3.eth.getBalance(userAdress)//constant.getBalance(userAdress)
        let bnbBalances = Number(fromWei(BalanceOf))
        // console.log('BNB余额：', Number(fromWei(BalanceOf)), bnbBalance) // 判断0.002BNB 买 最低充值0.01BNB
        if (boxType === 'USDT') {
            const usdtStatus = await constant.USDTTokenContract.methods.allowance(userAdress, Market_Address).call();
            const usdtBalanceOf = await constant.USDTTokenContract.methods.balanceOf(userAdress).call();
            let usdtnum = Number(fromWei(usdtStatus)) // 授权金额
            let usdtbalan = Number(fromWei(usdtBalanceOf))
            setUsdtBalance(usdtbalan)
            console.log(usdtnum, usdtbalan)
            if (Jine > usdtbalan) {
                setBnbBalance("USDT")
                setTimeout(() => {
                    console.log(Jine > usdtbalan, bnbBalance)
                    showModal() // 充值弹窗
                    setLoading(false)
                }, 200);
            } else if (bnbBalances < 0.002) {
                setBnbBalance("BNB")
                setTimeout(() => {
                    showModal() // 充值弹窗
                    setLoading(false)
                }, 200);
            } else {
                if (Jine >= usdtnum) {
                    approve() // 授权 console.log('授权')
                } else {
                    buyBox() // 授权 console.log('授权')
                }
            }
        } else {
            const mtsStatus = await constant.MTSTokenContract.methods.allowance(userAdress, Market_Address).call();
            const mtsBalanceOf = await constant.MTSTokenContract.methods.balanceOf(userAdress).call();
            const marketBalanceOf = await constant.USDTTokenContract.methods.balanceOf(Market_Address).call();
            let mtsnum = Number(fromWei(mtsStatus))
            let mtsbalan = Number(fromWei(mtsBalanceOf))
            setMtsBalance(mtsbalan)
            let marketNum = Number(fromWei(marketBalanceOf))
            // console.log('Market_Address在USDT的代币余额：', marketNum, '自己的MTS余额:', mtsbalan, mtsnum)
            if (Jine >= marketNum || Jine >= mtsbalan) {
                message.warning('Insufficient user balance')
                setLoading(false)
            } else if (bnbBalances < 0.002) {
                setBnbBalance("BNB")
                setTimeout(() => {
                    showModal() // 充值弹窗
                    setLoading(false)
                }, 200);
            } else {
                if (Jine >= mtsnum) {
                    approve()
                } else {
                    buyBox()
                }
            }
        }
    }
    // 代币授权
    const approve = async () => {
        setLoading(true)
        if (boxType === 'USDT') {
            await constant.USDTTokenContract.methods
                .approve(Market_Address, Web3.utils.toWei('1000000000', 'ether'))
                .send({
                    from: userAdress,
                })
                .on('transactionHash', function (hash: any) {
                    // console.log(hash)
                    // setLoading(false)
                })
                .on('receipt', async (receipt: any) => {
                    buyBox()
                })
                .on('error', function (error: any, receipt: any) {
                    setLoading(false)
                    message.error(error.message)
                })
        } else {
            await constant.MTSTokenContract.methods
                .approve(Market_Address, Web3.utils.toWei('1000000000', 'ether'))
                .send({
                    from: userAdress,
                })
                .on('transactionHash', function (hash: any) {
                })
                .on('receipt', async (receipt: any) => {
                    buyBox()
                })
                .on('error', function (error: any, receipt: any) {
                    setLoading(false)
                    message.error(error.message)
                })
        }
    }

    // USDT\MTS购买
    const blindBox = async (type: string) => {
        let winner = solfDataInfo?.winner;
        let accountUser = GetRequest('address') ? GetRequest('address') : Token_ADDRESS
        if (winner != 0) {
            message.warning('The champion team has been created')
            return
        }
        if (userAdress) {
            setBoxType(type)
            if (accountUser != Token_ADDRESS) {
                // console.log('查询邀请者地址')
                const superiorAddress = await constant.MarketContract.methods.superior(accountUser).call() // 获取邀请者地址
                // console.log('查询邀请者地址', superiorAddress)
                if (superiorAddress === userAdress) {
                    message.warning('Can`t invite each other')
                }
            }
            setBoxShow(true)
        } else {
            message.warning('Please link the wallet')
        }
    }
    // 购买盲盒按钮
    const BlindBoxConnect = () => {
        allowance()
    }
    // 购买盲盒事件
    const buyBox = async () => {
        let sucdata: any = []
        if (boxNum === 1) {
            sucdata.push(boxNum)
        } else {
            for (let i = 1; i <= boxNum; i++) {
                sucdata.push(boxNum[i])
            }
        }
        setSucNum(sucdata)
        let able: number = solfDataInfo?.available ? solfDataInfo?.available : 0
        if (boxNum <= 0) {
            message.warning('Purchase quantity is not less than 1')
            return
        }
        if (boxNum > able) {
            message.warning(`Currently, the remaining ${able} mystery boxes can be purchased`)
            return
        }
        let accountUser = GetRequest('address') ? GetRequest('address') : Token_ADDRESS
        if (accountUser === userAdress) {
            accountUser = Token_ADDRESS
        } else {
            accountUser = GetRequest('address') ? GetRequest('address') : Token_ADDRESS
        }
        let token_ = boxType === 'USDT' ? USDTToken_Address : MTSToken_Address
        setLoading(true)
        // ation 1、越南 2、泰国 
        constant.MarketContract.methods.purchase(accountUser, token_, boxNum, ation)
            .send({ from: userAdress })
            .on('transactionHash', function (hash: any) {
            })
            .on('receipt', async (receipt: any) => {
                message.success('success')
                boxTotal()
                setBoxShow(false)
                setSucShow(true)
                setLoading(false)
                setBoxNum(1)
                setJine(10)
            })
            .on('error', function (error: any, receipt: any) {
                setLoading(false)
                message.error(error.message)
            })
    }
    // 复制地址
    const copyClick = () => {
        if (userAdress) {
            if (Copyto(address)) {
                message.success('Copy succeeded')
            } else {
                message.error('Copy failed')
            }
        } else {
            message.warning('Please link the wallet')
        }
    }
    // 领取奖励
    const winnerClaimSend = async () => {
        let num = Number(solfDataInfo?.share)
        if (queryTeam) {
            message.warning('Rewards have been received')
            return
        }
        if (num <= 0) {
            message.warning('No reward available')
            return
        }
        setLoading(true)
        constant.MarketContract.methods.winnerClaim()
            .send({ from: userAdress })
            .on('transactionHash', function (hash: any) {
                // console.log('领取奖励', hash)
            })
            .on('receipt', async (receipt: any) => {
                boxTotal()
                setLoading(false)
                message.success('Successfully received')
            })
            .on('error', function (error: any, receipt: any) {
                setLoading(false)
                message.error(error.message)
            })
    }
    /**
     * 金额输入框
     */
    const numChange = (e: any) => {
        let pattren = new RegExp(numberRegExpS)
        let inputNUm = e.target.value
        let able: number = solfDataInfo?.available ? solfDataInfo?.available : 0
        if (pattren.test(inputNUm)) {
            if (inputNUm > able) {
                message.warning(`Currently, the remaining ${able} mystery boxes can be purchased`)
                setJine(able * 10)
                setBoxNum(able)
                setlistBox(able)
                return
            }
            setBoxNum(e.target.value)
            setlistBox(e.target.value)
            setJine(e.target.value * 10)
        } else {
            setJine(0 * 10)
            setBoxNum(null)
            message.warning('Please enter a positive integer')
        }
    }
    /**
     * 减号
     */
    const ClickJian = () => {
        let num: number = boxNum;
        if (num > 1) {
            num--
            setJine(num * 10)
            setBoxNum(num)
            setlistBox(num)
        }
    }
    /**
     * 加号
     */
    const ClickAdd = () => {
        let num: number = boxNum;
        num++
        setJine(num * 10)
        setBoxNum(num)
        setlistBox(num)
        // console.log(num, boxNum, listBox)
    }
    /**
   * 打开充值弹窗
   * Open the recharge pop-up window
   */
    const showModal = () => {
        setIsModalOpen(true);
        monitorToken()
    };
    /**
     * 关闭充值弹窗
     * Close the recharge pop-up window
     */
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
    };
    // 法币币种及支付方式查询
    const getFiatList = () => {
        Http.post('fiat/list').then((res: any) => {
            if (res.data.code === 0) {
                // console.log('法币币种及支付方式查询', res.data.data)
                let data = res.data.data;
                let str = data.filter((el: any) => {
                    return el.payWayCode === "10001"
                })
                let arr = str.filter((el: any) => {
                    return el.country === 'US' || el.country === 'TH' || el.country === 'VN'
                })
                let screen = arr.filter((el: any) => {
                    if (el.country === 'US') {
                        setNumAmount(el.payMin)
                    }
                    return el.currency === 'USD' || el.currency === 'THB' || el.currency === 'VND'
                })
                setFiatData(screen)
                console.log(screen, '法币币种及支付方式查询')
            } else {
                message.error(`${res.data.msg},法币币种及支付方式查询`)
            }
        })
    }
    const emailInput = (event: any) => {
        setEmailData(event.target.value)
    }
    /**
     * 支付事件
     * Payment Events
     * @param val 
     */
    const onFinish = (val: any) => {
        if (bnbBalance === 'USDT') {
            if (daibi === 'USD') {
                console.log(conversionScale, (Jine - usdtBalance))
                if (conversionScale < (Jine - usdtBalance)) {
                    console.log(((form.getFieldsValue().amount / conversionScale) * (Jine - usdtBalance)).toFixed(8), 'USD')
                    message.warning({ content: `Minimum recharge：${((form.getFieldsValue().amount / conversionScale) * (Jine - usdtBalance)).toFixed(8)}`, key: 'min' })
                    return
                }
            } else if (daibi === 'THB') {
                if (conversionScale < (Jine - usdtBalance)) {
                    console.log(((form.getFieldsValue().amount / conversionScale) * (Jine - usdtBalance)).toFixed(0))
                    message.warning({ content: `Minimum recharge：${((form.getFieldsValue().amount / conversionScale) * (Jine - usdtBalance)).toFixed(0)}`, key: 'min' })
                    return
                }
            } else if (daibi === 'VND') {
                if (conversionScale < (Jine - usdtBalance)) {
                    message.warning({ content: `Minimum recharge：${((form.getFieldsValue().amount / conversionScale) * (Jine - usdtBalance)).toFixed(0)}`, key: 'min' })
                    return
                }
            }
        } else {
            if (daibi === 'USD') {
                if (val.amount < (fiatData[1]?.payMin * 1.04).toFixed(8)) {
                    message.warning({ content: `Minimum recharge${(fiatData[1]?.payMin * 1.04).toFixed(8)}`, key: 'min' })
                    return
                }
            } else if (daibi === 'THB') {
                if (val.amount < (fiatData[3]?.payMin * 1.04).toFixed(0)) {
                    message.warning({ content: `Minimum recharge${(fiatData[3]?.payMin * 1.04).toFixed(0)}`, key: 'min' })
                    return
                }
            } else if (daibi === 'VND') {
                if (val.amount < (fiatData[2]?.payMin * 1.04).toFixed(0)) {
                    message.warning({ content: `Minimum recharge${(fiatData[2]?.payMin * 1.04).toFixed(0)}`, key: 'min' })
                    return
                }
            }
        }
        setLoading(true)
        let dataForm = {
            email: val.email,
            amount: String(val.amount), // 金额
            fiatCurrency: val.fiatCurrency,//"USD", // 法币名称  //VND
            cryptoCurrency: bnbBalance, // 数字货币名称
            address: userAdress, //crypto接收地址
            network: "BSC",  //crypto接收网络，USeg：ETH/BNB/BSC/ATOM/ONT/
            alpha2: transFiatCurrency(val.fiatCurrency), // 二位字母国家 //VN
            payWayCode: "10001"// 支付方式 //val.fiatCurrency === "VND" ? val.payWayCode : 
        }

        Http.post('pay', dataForm).then((res: any) => {
            setLoading(false)
            if (res.data.code === 0) {
                window.open(res.data.data.payUrl)
                sessionStorage.setItem('email', val.email)
            } else {
                message.error(res.data.msg)
            }
        })
    }
    /**
     * 数值更新 时换算
     * 数量换算
     */
    const onFieldsChange = (changedFields: any, allFields: any) => {
        // console.log(changedFields, allFields)
        setDaibi(allFields[1].value)
        if (allFields[2].value === '') {
            console.log('allFields[2].value', allFields[2].value)
            return
        }
        if (changedFields) {
            getConversionScale()
        }
        if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(allFields[0].value))) {
            return
        }
    }
    /**
     * 数量换算
     */
    const getConversionScale = () => {
        let dataForm = {
            crypto: bnbBalance,
            network: 'BSC',
            fiat: form.getFieldsValue().fiatCurrency,
            amount: Number(form.getFieldsValue().amount)
        }
        console.log(dataForm, '数量换算')
        Http.post('order/quote', dataForm).then((res: any) => {
            if (res.data.code === 0) {
                setConversionScale(res.data.data.cryptoQuantity)
            } else {
                // message.warning({ content: res.data.msg, key: 'Invalid' })
            }
        })
    }

    return (
        <SoldOutWral>
            <div className="soldStyle">
                <span className="soldout">SOLD OUT</span>
                <div className="numstyle">
                    <div style={{ color: '#00E2FF' }}>{solfDataInfo?.current}</div>
                    <div style={{ color: '#FFFFFF ' }}>/{solfDataInfo?.total}</div>
                </div>
                {
                    mentType === '0' ? <div className="boxGou">
                        <img src={Usdt} alt="" onClick={() => blindBox('USDT')} />
                    </div> : (mentType === '1' ? <div className="boxGou">
                        <img src={Mts} alt="" onClick={() => blindBox('MTS')} />
                    </div> : <div className="boxGou">
                        <img src={Usdt} alt="" onClick={() => blindBox('USDT')} />
                        <img src={Mts} alt="" onClick={() => blindBox('MTS')} />
                    </div>)
                }
                <div className="copyData">
                    <span>Invite your friends to buy with you and get a 10% rebate</span>
                    <div className="copyDataShow">
                        <span>{address}</span>
                        <img src={Copy_icon} alt="" onClick={copyClick} />
                    </div>
                </div>
                <div className="total">
                    <div className='title'>
                        <img src={total} alt="" />
                    </div>
                    <div className='guan-icon'>
                        {
                            solfDataInfo?.winner > 0 ? <img src={require(`@/assets/NFT/${solfDataInfo?.winner}.png`)} alt="" />
                                : <img src={wh_icon} alt="" />
                        }
                    </div>
                    <NumberRoll />
                    <div className="receiveStyle">
                        <div className='timeText'>2022/12/19 18:00(UTC) Announced</div>
                        <div className='BtnClaim'>The champion will receive the sum：
                            {
                                queryTeam ? <span className='theSum'>$0</span>
                                    : <span className='theSum'>${solfDataInfo?.share}</span>
                            }
                        </div>
                        {
                            queryTeam ? <div className='JinBbtn' style={{ opacity: 0.2 }}></div>
                                : <div className='JinBbtn' style={{ opacity: 1 }} onClick={winnerClaimSend}></div>
                        }
                    </div>
                    {/* {
                        solfDataInfo?.winner === 0 && queryTeam === true ? <></> : solfDataInfo?.share <= 0 ? <></> : <div className="receiveStyle">
                            <div className='BtnClaim'>Amount received:{solfDataInfo?.share}</div>
                            <div className='JinBbtn' onClick={winnerClaimSend}>receive</div>
                        </div>
                    } */}
                </div>
            </div >
            {
                loading && <Loading />
            }
            <Modal visible={boxShow} footer={null} onCancel={() => {
                setBoxShow(false)
                setJine(10)
                setBoxNum(1)
            }} width="80%" className="blindBoxModal" centered closable={false}>
                <BlindBoxWrarl>
                    <div className='topClose'>
                        <img src={close} alt="" onClick={() => {
                            setJine(10)
                            setBoxNum(1)
                            setBoxShow(false)
                        }} />
                    </div>
                    <div className="boxstyle">
                        <div className='sucBox'>
                            <div className='flag'>
                                <video src={boxDefault} autoPlay muted={true} loop preload="auto" poster={cardImg} webkit-playsInline={true} playsInline={true}></video>
                            </div>
                        </div>
                        <div className='inputtotal'>
                            <div className='total'>TOTAL AMOUNT：<span className='bule'>{Jine} {boxType}</span></div>
                            <div className='Btnjia'>
                                <img src={jian} alt="" className='jian' onClick={ClickJian} />
                                <Input className="input" value={boxNum} onChange={numChange} />
                                <img src={jia} alt="" className='jia' onClick={ClickAdd} />
                            </div>
                        </div>
                        <img src={boxBom} alt="" className='boxBomImg' />
                        <BtnSucceed onClick={BlindBoxConnect}>
                            <BtnText>MINT</BtnText>
                        </BtnSucceed>
                    </div>
                </BlindBoxWrarl>
            </Modal>
            {/* 盲盒成功后的弹窗 */}
            <Modal visible={sucShow} footer={null} onCancel={() => setSucShow(false)}
                width="80%" className="SucModal" centered closable={false}>
                <SucWrarl>
                    <div className='sucBox' >
                        {
                            listBox < 11 ? sucNum.map((el: any, i: number) => {
                                return (
                                    <div className='flag' key={i}>
                                        <video src={boxDefault} autoPlay muted={true} loop poster={cardImg} webkit-playsInline={true} playsInline={true}></video>
                                    </div>
                                )
                            })
                                : sucNumOuy.map((el: any, ou: number) => {
                                    return (
                                        <div>
                                            <div className='flag' key={ou}>
                                                <video src={boxDefault} autoPlay muted={true} loop poster={cardImg} webkit-playsInline={true} playsInline={true}></video>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                        {
                            listBox < 11 ? <></> : <div className='flag' >
                                <div className='tipsNUm'>+{listBox - 11}</div>
                            </div>
                        }
                    </div>
                    <div className='Btnbottom'>
                        <div className='Succee' onClick={() => setSucShow(false)}><span className='btntext'>Succeed</span></div>
                        <div className='Open' onClick={() => history.replace('/profile/' + 3)}> <span className='btntext'>Open</span></div>
                    </div>
                </SucWrarl>
            </Modal>
            {/* Token recharge 代币充值 */}
            {/* width={window.innerWidth < 400 ? "100%" : "40%"} */}
            <Modal visible={isModalOpen} onCancel={handleCancel} footer={null}
                className="TokenModal" centered closable={false}>
                <TokenWral>
                    <div className='title-top-style'>
                        <div className='title'>Purchase blind box</div>
                        <img src={clane} alt="" onClick={handleCancel} />
                    </div>
                    <div className='conent-style'>
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            form={form}
                            onFieldsChange={onFieldsChange}
                            autoComplete="off"
                            initialValues={{
                                email: sessionStorage.getItem('email'),
                                fiatCurrency: daibi,//'USD',
                                amount: numAmount//(bnbBalance !== 'USDT') ? '0.01' : (Jine - usdtBalance)
                            }}
                        >
                            <Form.Item
                                label="Mail："
                                name="email"
                                rules={[
                                    { type: 'email', message: 'The input is not valid E-mail!' },
                                    { required: true, message: 'Please enter your E-mail!' }
                                ]}
                            >
                                <Input placeholder='XXX@gmail.com' className="input" onChange={emailInput} />
                            </Form.Item>

                            <Form.Item
                                label="Payment Currency:"
                                name="fiatCurrency"
                                rules={[{ required: true, message: 'Please select currency type' }]}
                                style={{ 'margin': "0px" }}
                            >
                                <Radio.Group>
                                    <Radio value="VND"> VND </Radio>
                                    <Radio value="USD"> USD </Radio>
                                    <Radio value="THB"> THB </Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: "4px" }}>
                                <div>
                                    {
                                        daibi === 'USD' && <div style={{ color: '#FFF', fontSize: '10px' }}>{fiatData[1]?.currency} Minimum recharge：{fiatData[1]?.payMin}, {fiatData[1]?.currency} maximum recharge：{fiatData[1]?.payMax}</div>
                                    }
                                    {
                                        daibi === 'THB' && <div style={{ color: '#FFF', fontSize: '10px' }}>{fiatData[3]?.currency} Minimum recharge：{fiatData[3]?.payMin}, {fiatData[3]?.currency} maximum recharge：{fiatData[3]?.payMax}</div>
                                    }
                                    {
                                        daibi === 'VND' && <div style={{ color: '#FFF', fontSize: '10px' }}>{fiatData[2]?.currency} Minimum recharge：{fiatData[2]?.payMin}, {fiatData[2]?.currency} maximum recharge：{fiatData[2]?.payMax}</div>
                                    }
                                </div>
                            </Form.Item>
                            {/* {
                                daibi === 'VND' && <Form.Item
                                    label="Payment method:"
                                    name="payWayCode"
                                    rules={[{ required: true, message: 'Please select currency type' }]}
                                    style={{ marginBottom: "10px" }}
                                >
                                    <Radio.Group>
                                        <Radio value="10001"> Visa </Radio>
                                        <Radio value="201"> MoMo </Radio>
                                        <Radio value="202"> ZaloPay </Radio>
                                        <Radio value="203"> ViettelPay </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            } */}

                            <Form.Item
                                label="Recharge Amount："
                            >
                                <Row>
                                    <Col span={16}>
                                        <Form.Item
                                            style={{ 'marginBottom': "0px" }}
                                            name="amount"
                                            rules={[{ required: true, message: 'Please enter the recharge amount' }]}
                                        >
                                            <InputNumber className="inputNumber"
                                                placeholder='Recharge Amount'
                                                // defaultValue={numAmount}//(bnbBalance !== 'USDT') ? '0.01' : (Jine - usdtBalance)}
                                                // value={numAmount}
                                                // onChange={(e: any) => setNumAmount(e.target.value)}
                                                min={daibi === 'USD' ? fiatData[1]?.payMin : (daibi === 'THB' ? fiatData[3]?.payMin : fiatData[2]?.payMin)}
                                                max={daibi === 'USD' ? fiatData[1]?.payMax : (daibi === 'THB' ? fiatData[3]?.payMax : fiatData[2]?.payMax)}
                                                onChange={() => onFieldsChange}
                                                controls={false}
                                                precision={8}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} style={{ lineHeight: '40px' }}>
                                        <span style={{ marginLeft: '10px', marginTop: '10px', color: '#FFFFFF' }}>≈{conversionScale} {bnbBalance}</span>
                                    </Col>
                                    {/* <Col span={24} style={{ lineHeight: '40px' }}>
                                        {
                                            bnbBalance === "USDT" ? <div style={{ color: '#FFF' }}>You need {Jine}{boxType} to purchase the blind box, and you still need {boxType === "USDT" ? Jine - usdtBalance : Jine - mtsBalance}{boxType}</div>
                                                : <div style={{ color: '#FFF' }}>Insufficient BNB balance, minimum deposit 0.01BNB</div>
                                        }
                                    </Col> */}
                                </Row>
                            </Form.Item>

                            {/* <Form.Item></Form.Item> */}

                            <Form.Item wrapperCol={window.innerWidth < 400 ? { offset: 8, span: 16 } : { offset: 6, span: 18 }} className="btn-payment">
                                <BtnSucceed>
                                    <Button htmlType="submit" className='textPayment'>PAYMENT</Button>
                                </BtnSucceed>
                            </Form.Item>
                        </Form>
                    </div>
                </TokenWral>
            </Modal>
        </SoldOutWral >
    )
})
