import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import Web3 from 'web3'

const web3 = new Web3(Web3.givenProvider)

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  NetWork = 'NetWork',
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

export const RPC_URLS: { [chainId: number]: string } = {
  1337: 'http://47.108.77.85:8545',
}

export const injected = new InjectedConnector({ supportedChainIds: [1337] })

export const walletconnect = new WalletConnectConnector({
  rpc: { 1337: RPC_URLS[1337] },
  qrcode: true,
  // supportedChainIds: [97, 43113, 1337],
  chainId: 1337,
})

export const network = new NetworkConnector({
  urls: { 1337: RPC_URLS[1337] },
  defaultChainId: 1337,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.NetWork]: network,
}

export const defaultChainId = 1337

export const useConstant = {
  1337: {
    CHAIN_ID: 1337,
    RPC_URL: 'http://47.108.77.85:8545',
    Token_ADDRESS: '0x0000000000000000000000000000000000000000',
    FundProvider_Address: '0x6eAe59125991E1bc47A9Fd58F94F3ABBba53F778',
    fromWei: 'ether',
  },
}

export const netWorks = {
  1337: {
    chainId: web3.utils.numberToHex(1337),
    isSwitch: true,
  },
}
