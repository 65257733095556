import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import Web3 from 'web3'

const web3 = new Web3(Web3.givenProvider)

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  NetWork = 'NetWork',
  Bsc = 'bsc'
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

export const RPC_URLS: { [chainId: number]: string } = {
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
}

export const bsc = new BscConnector({
  supportedChainIds: [97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
})

export const injected = new InjectedConnector({ supportedChainIds: [97] })

export const walletconnect = new WalletConnectConnector({
  rpc: { 97: RPC_URLS[97] },
  qrcode: true,
  chainId: 97,
  // supportedChainIds: [1337, 97],
})

export const network = new NetworkConnector({
  urls: { 97: RPC_URLS[97] },
  defaultChainId: 97,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.NetWork]: network,
  [ConnectorNames.Bsc]: bsc,
}

export const defaultChainId = 97

export const useConstant = {
  97: {
    CHAIN_ID: 97,
    RPC_URL: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    Token_ADDRESS: '0x0000000000000000000000000000000000000000',
    FundProvider_Address: '0x900e15E8a43c6Cd7A4dBCeDCbE667Ee8b03d4948',
    
    USDTToken_Address: '0xB293c5f2824D691e76b0536EC75AF9C6137a04ed',
    MTSToken_Address: '0x4A2B343C0Ad7f045f65697F4D8405Ef8720C8b7b',

    WorldCupCard_Address: '0x418AAf19E650C9F12C3140cd8638bd76B7e869a9',
    BlindBox_Address: '0x660Ef4B996A7D66f0FaD2763855501f29617a8a3',
    Market_Address: '0xC3B33F6Ea916C5cdF6ECa3DF68f805856E7E2da9',
    Oracle_Address: '0xFF55B32740A8231B22dDAcb9763dF61bAbc3956A',
    fromWei: 'ether',
  },
}

export const netWorks = {
  97: {
    chainId: web3.utils.numberToHex(97),
    chainName: 'BNB Smart Chain Testnet',
    nativeCurrency: {
      name: 'Bnb',
      symbol: 'Bnb',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
}
