import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SwitchLanguage from '../SwitchLanguage'
import { TopBarWrapper } from './styled'
import { Image, message } from 'antd'
import Connectwallet from '@/components/ConnectWallet'
import { Link, useHistory } from 'react-router-dom'
import LOGO from '@/assets/Img/logo.png'
import Road from '@/assets/Img/road.png'
import Profile from '@/assets/Img/profile.png'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useSelector } from 'react-redux'

interface type {
  haveId: any
}

export default memo(function TopBarPages(props: type) {
  const { haveId } = props;
  const { t } = useTranslation();
  const context = useWeb3React<Web3Provider>()
  const { account } = context;
  const walletInfo = useSelector((state: any) => state.walletInfo)
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const userAdress = walletInfo.wallet === 'Bsc' ? myAddress : account;

  const history = useHistory();
  useEffect(() => {
    // console.log('myAddress', userAdress)
  }, [userAdress])

  const ClickProfile = () => {
    if (userAdress) {
      history.replace('/profile/' + 1)
    } else {
      message.warning('Please link the wallet')
    }
  }
  const RoadClick = () => {
    if (userAdress) {
      history.replace('/profile/' + 3)
    } else {
      message.warning('Please link the wallet')
    }
  }
  return (
    <TopBarWrapper>
      <div className='topbarText'>
        <div>
          <Link to="/home">
            <Image src={LOGO} className="logo" preview={false} />
          </Link>
        </div>
        <div className='textRigth'>
          <Image src={Road} className="road" preview={false} onClick={RoadClick} />
          <Image src={Profile} className="profile" preview={false} onClick={ClickProfile} />
          <Connectwallet />
        </div>
      </div>
    </TopBarWrapper>
  )
})
