import Carousel from '@/components/Carousel'
import Loading from '@/components/Loading'
import Reward from '@/components/Reward'
import Roadmap from '@/components/Roadmap'
import SoldOut from '@/components/SoldOut'
import TopBar from '@/components/TopBar'
import { useMarketHooks } from '@/hooks/useMarketHooks'
import { useWorldCupCardHooks } from '@/hooks/useWorldCupCardHooks'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiflWprpld, HomeWrapper } from './styled'

export default memo(function HomePages(props: any) {
  const { t, i18n } = useTranslation()
  const [homeInfo, setHomeInfo] = useState<any>()
  // const dataInt: any = useWorldCupCardHooks()
  // const { WorldCupCardData } = dataInt
  useEffect(() => {
    console.log('首页')
  }, [])

  useEffect(() => {
    da()
  }, [window.location.href])

  const da = () => {
    const herf = window.location.href
    if (herf.indexOf('?') !== -1) {
      const name = herf.split('?')[1].split('=')[1]
      // console.log(name)
      if (name.indexOf('road') !== -1) {
        const a = document.getElementById('road')
        // console.log(a)
        if (a) {
          a.scrollIntoView(
            { block: "center" }
          );
        }
      }
    }
  }

  return (
    <HomeWrapper>
      <div className='home'>
        <div className='mainDrawing'>
          <TopBar haveId={homeInfo?.id || '---'} />
          <FiflWprpld />
          <Carousel />
          <SoldOut />
        </div>
        <Reward />
        <div id='road'>
          <Roadmap />
        </div>
      </div>
    </HomeWrapper >
  )
})

