import styled from 'styled-components'
import footer from '@/assets/Img/footer.png'

export const RoadmapWral = styled.div`
  width: 100%;
  height: auto;
  margin-top: 130px;
  .footerStyle {
    width: 100%;
    height: 900px;
    background-image: url('${footer}');
    background-size: 100% 100%;
    text-align: center;
    .img-rew {
      display: flex;
      margin: 100px auto -10px;
      width: 519px;
    }
    .conent {
      width: 1208px;
      position: relative;
    }
  }
  .text-ter{
    background: #040E45;
    .footerText{
      max-width: 1334px;
      min-width: 750px;
      margin: auto;
      .title{
          font-size: 24px;
          font-family: AvenirNext-HeavyItalic;
          color: #00CBEB;
          font-weight: 800;
      }
      .conentText{
        font-size: 18px;
        color: #FFF;
        font-weight: 500;
      }
    }
      .footerIcon{
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        img{
          cursor: pointer;
          width: 60px;
          margin: 0 56px;
        }
    }
  }
  @media (max-width: 768px) {
    .footerStyle {
      background-size: auto 100%;
      height: 1800px;
      background-position: center;
      .img-rew {
        display: flex;
        width: 1038px;
      }
      .conent {
        position: relative;
        margin-top: 120px;
        width: 1800px;
      }
      .text-ter{
        background: #040E45;
        .footerText{
          .title{
            font-size: 24px;
            font-family: AvenirNext-HeavyItalic;
            color: #00CBEB;
            font-weight: 800;
          }
          .conentText{
            font-size: 18px;
            color: #FFF;
            font-weight: 500;
          }
        }
        .footerIcon{
          display: flex;
          flex-wrap: wrap;
          padding: 90px;
          justify-content: center;
          img{
            cursor: pointer;
            width: 120px;
            margin: 0 60px;
          }
        }
      }
    }
  }
`
