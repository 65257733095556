import styled from 'styled-components'
import xk from '@/assets/Img/xk.png'
import boxBtn from '@/assets/Img/min.png'
import boxBtns from '@/assets/Img/boxBtn.png'
import Receive from '@/assets/Img/Receive.png'
import cardbj from '@/assets/Img/cardbj.png'

export const ProfileWrapper = styled.div`
  width: 100%;
  background: #000;
  height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .topbar {
    background: #040e45;
  }
  .tabs {
    width: 984px;
    max-width: 1060px;
    margin: 20px auto;
    .income {
      background: rgba(1, 6, 33, 0.27);
      /* box-shadow: 4px 4px 0px 0px rgba(1,8,25,0.6), 0px -4px 0px 0px #075677; */
      border: 1px solid #00deff;
      border-radius: 1px;
      width: 894px;
      height: 800px;
      .topcome {
        background: #00deff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1px 1px 0px 0px;
        height: 163px;
        .textcome {
          color: #ffffff;
          text-shadow: 0px 4px 4px #1b0843;
          -webkit-text-stroke: 1px #1b0743;
          font-weight: normal;
          .zm {
            font-size: 20px;
            font-family: AvenirNext-HeavyItalic;
          }
          .num {
            font-size: 40px;
            font-family: AvenirNext-HeavyItalic;
          }
        }
      }
      .text-total {
        width: 900px;
        max-width: 800px;
        font-size: 24px;
        font-family: Avenir Next;
        font-weight: normal;
        font-style: italic;
        color: #ffffff;
        margin: 40px auto 0;
        .colorBule {
          margin: 0 8px;
          color: #00deff;
        }
        .get-rerwards {
          margin: 20px auto;
          /* height: 40px; */
          display: flex;
          justify-content: center;
          text-align: center;
          flex-direction: column;
          align-items: center;
          .BtnClaim {
            margin-top: 10px;
            /* width: 143px; */
            height: 64px;
            cursor: pointer;
          }
        }
        .text-1 {
          text-align: center;
          margin: 40px auto 0;
        }
      }
      .copypic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 570px;
        height: 50px;
        background: rgba(0, 50, 132, 0.8);
        border: 1px solid #00e2ff;
        border-top-left-radius: 108.8px;
        border-bottom-right-radius: 108.8px;
        margin: 50px auto 0;
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        padding: 0 30px;
        .copyAddress {
          width: 360px;
          overflow: hidden; /* 超出一行文字自动隐藏 */
          text-overflow: ellipsis; /*文字隐藏后添加省略号*/
          white-space: nowrap; /*强制不换行*/
        }
        img {
          cursor: pointer;
          width: 90px;
          height: 21px;
        }
      }
    }
    .mynft {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 940px;
      height: 700px;
      background: rgba(1, 6, 33, 0.27);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .nodata {
        display: flex;
        margin-left: 50%;
        align-items: center;
      }
      .nftStyle {
        text-align: center;
        width: 293px;
        height: 360px;
        .flag {
          background-image: url('${cardbj}');
          background-size: 100% 100%;
          width: 293px;
          height: 293px;
          position: relative;
          .flag-img {
            position: absolute;
            left: 18%;
            top: 6%;
            width: 65%;
          }
          video {
            width: 80%;
            margin-top: 19px;
          }
          .boxData {
            width: 80px;
            height: 36px;
            background: #00deff;
            line-height: 36px;
            color: #040e45;
            font-size: 16px;
            font-weight: bold;
            position: absolute;
            border-radius: 8px;
            right: 3%;
            top: 80%;
          }
        }
        .text {
          color: #00e2ff;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .mystert {
      display: flex;
      justify-content: center;
      /* align-items: center; */
      /* width: 940px;
      height: 675px;
      overflow-y: scroll; */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (max-width: 768px) {
      max-width: unset;
      width: 1900px;
      .ant-tabs-tab {
        position: relative;
        margin-left: 40px;
      }
      .ant-tabs-tab-btn {
        font-weight: 600;
        font-size: 100px;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 600;
        font-size: 100px;
      }
      .income {
        width: unset;
        height: unset;
        .topcome {
          height: unset;
          .textcome {
            .zm {
              position: relative;
              font-size: 7px;
            }
            .num {
              position: relative;
              font-size: 100px;
            }
          }
        }
        .text-total {
          color: #ffffff;
          width: 1600px;
          max-width: unset;
          font-size: 70px;
          .get-rerwards {
            .BtnClaim {
              height: 44px;
            }
          }
        }
        .copypic {
          width: 80%;
          height: unset;
          margin-bottom: 100px;
          font-size: 60px;
          img {
            cursor: auto;
            width: 360px;
            height: 84px;
          }
        }
      }
      .mynft {
        position: relative;
        width: 1900px;
        height: 80vh;
        align-content: flex-start;
        .nftStyle {
          text-align: center;
          width: 600px;
          height: 800px;
          margin: 12px;
          .flag {
            position: relative;
            width: 600px;
            height: 600px;
            .boxData {
              position: absolute;
              bottom: 100px;
              right: 50px;
              top: unset;
              left: unset;
              width: unset;
              height: unset;
              font-size: 80px;
              line-height: 80px;
            }
          }
          .text {
            font-weight: bold;
            font-size: 80px;
          }
        }
      }
    }
  }
`
export const BlindBoxWrarl = styled.div`
  padding: 30px;
  title {
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
  }
  .topClose {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 200px;
    img {
      cursor: pointer;
    }
  }
  .boxstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .boxText {
      width: 480px;
      font-size: 24px;
      margin: 20px auto;
      font-family: AvenirNext-Medium;
      color: #fff;
      .bule {
        color: #00deff;
      }
    }
    .flag {
      background-image: url('${cardbj}');
      background-size: 100% 100%;
      width: 293px;
      height: 293px;
      video {
        width: 80%;
        margin-top: 19px;
      }
      .boxData {
        width: 80px;
        height: 36px;
        background: #00deff;
        line-height: 36px;
        color: #040e45;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        border-radius: 8px;
        right: -180px;
        top: -40px;
      }
    }
  }
  .tokenstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .flag {
      background-image: url('${cardbj}');
      background-size: 100% 100%;
      width: 420px;
      height: 420px;
      video {
        width: 80%;
        margin-top: 26px;
      }
    }
    .boxBom {
      width: 420px;
      margin-top: -50px;
    }
  }
  @media (max-width: 768px) {
    .topClose {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 200px;
      img {
        cursor: pointer;
        width: 120px;
        height: 120px;
      }
    }
    .boxstyle {
      .boxText {
        width: unset;
        font-size: 80px;
      }
      .flag {
        position: relative;
        width: 1000px;
        height: 1000px;
        video {
          position: absolute;
          top: 50px;
          left: 100px;
        }
        .boxData {
          position: absolute;
          right: 100px;
          bottom: 140px;
          left: unset;
          top: unset;
          width: unset;
          height: unset;
          font-size: 80px;
          line-height: 80px;
        }
      }
    }
    .tokenstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .flag {
      background-image: url('${cardbj}');
      background-size: 100% 100%;
      width: 1020px;
      height: 1020px;
      video {
        width: 80%;
        margin-top: 70px;
      }
    }
    .boxBom {
      position: relative;
      width: 820px;
      margin-top: -50px;
    }
  }
}
`

export const BtnSucceed = styled.div`
  cursor: pointer;
  margin-top: 15px;
  width: 281px;
  height: 78px;
  text-align: center;
  line-height: 68px;
  background-image: url('${boxBtn}');
  background-size: 100% 100%;
  @media (max-width: 768px) {
    cursor: auto;
    margin-top: 60px;
    width: 1124px;
    height: 312px;
    line-height: 272px;
  }
`
export const BtnSucceeds = styled.div`
  cursor: pointer;
  margin-top: 15px;
  width: 281px;
  height: 78px;
  text-align: center;
  line-height: 68px;
  background-image: url('${boxBtns}');
  background-size: 100% 100%;
  @media (max-width: 768px) {
    cursor: auto;
    margin-top: 60px;
    width: 1124px;
    height: 312px;
    line-height: 272px;
  }
`
export const BtnText = styled.div`
  font-style: italic;
  font-size: 32px;
  font-family: AvenirNextBold;
  font-weight: bold;
  color: #221849;
  text-shadow: 2px 5px 3px #be7505;
  -webkit-text-stroke: 1px #ffffff;
  text-stroke: 2px #ffffff;
  @media (max-width: 768px) {
    font-style: italic;
    font-size: 128px;
    text-shadow: 8px 20px 12px #be7505;
  }
`
