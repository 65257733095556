import styled from 'styled-components'
import number_bg from '@/assets/Img/number-bg.png'

export const NumberRollDiv = styled.div`
  .box-number {
    /* background: green; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 230px;
    padding-left: 100px;
    align-items: center;
    color: rgb(36, 35, 35);
    height: 100px;
    position: relative;
  }

  .list-wrap {
    width: 58px;
    height: 58px;
    background-image: url('${number_bg}');
    background-size: 100% 100%;
    text-align: center;
    overflow: hidden;
    margin: 1.6px;
    &.dh {
      margin-left: 0;
      margin-right: 30px;
      &::after {
        content: ',';
        display: block;
        position: absolute;
        bottom: 35px;
        padding-left: 65px;
        width: 30px;
        height: 58px;
        font-size: 57px;
        color: #00dbf9;
        font-family: AvenirNextBold;
      }
    }
  }

  .roll div {
    text-align: center;
    font-weight: bold;
    font-size: 56px;
    line-height: 58px;
    /* text-shadow: 0 2px 1px #000 !important; */
    font-family: AvenirNext-HeavyItalic;
    background: linear-gradient(0deg, #0bfcff 10%, #ffffff 70%) !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
  }

  .roll_1 {
    animation: roll_1 3s forwards;
  }
  .roll_2 {
    animation: roll_2 3s forwards;
  }

  .roll_3 {
    animation: roll_3 3s forwards;
  }

  .roll_4 {
    animation: roll_4 3s forwards;
  }

  .roll_5 {
    animation: roll_5 3s forwards;
  }

  .roll_6 {
    animation: roll_6 3s forwards;
  }

  .roll_7 {
    animation: roll_7 3s forwards;
  }

  .roll_8 {
    animation: roll_8 3s forwards;
  }

  /*省略roll_2——roll_8*/

  .roll_9 {
    animation: roll_9 2s forwards;
  }

  @keyframes roll_1 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -50%, 0);
    }
  }

  @keyframes roll_2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -66.66666%, 0);
    }
  }
  @keyframes roll_3 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -75%, 0);
    }
  }
  @keyframes roll_4 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -80%, 0);
    }
  }
  @keyframes roll_5 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -83.33333%, 0);
    }
  }
  @keyframes roll_6 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -85.71428%, 0);
    }
  }
  @keyframes roll_7 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -87.5%, 0);
    }
  }
  @keyframes roll_8 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -88.88888%, 0);
    }
  }
  @keyframes roll_9 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -90%, 0);
    }
  }
  @media (max-width: 768px) {
    transform: scale(1.8);
  }
`
