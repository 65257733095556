import Web3 from 'web3'
import { erc20_ABI, useConstant, FundProvider_ABI, USDTToken_ABI, MTSToken_ABI, BlindBox_ABI, Market_ABI, Oracle_ABI, WorldCupCard_ABI } from './constant'
interface ConstantTyps {
  CardContract: any
  FundProviderContract: any
  USDTTokenContract: any
  MTSTokenContract: any
  MarketContract: any
  OracleContract: any
  WorldCupCardContract: any
  BlindBoxContract: any
}

export interface ConstantInitTypes {
  web3: Web3
  Token_ADDRESS: string
  FundProvider_Address: string
  USDTToken_Address: string
  MTSToken_Address: string
  WorldCupCard_Address: string
  BlindBox_Address: string
  Market_Address: string
  Oracle_Address: string
  constant: ConstantTyps
  fromWei: string
}

export class ConstantInit {
  web3: Web3
  constant: ConstantTyps
  Token_ADDRESS: string
  FundProvider_Address: string
  USDTToken_Address: string
  MTSToken_Address: string
  WorldCupCard_Address: string
  BlindBox_Address: string
  Market_Address: string
  Oracle_Address: string
  fromWei: string

  constructor(provider: any, chainId: string) {
    console.log('provider', provider)
    const { Token_ADDRESS,
      FundProvider_Address,
      fromWei, USDTToken_Address,
      MTSToken_Address,
      WorldCupCard_Address,
      BlindBox_Address,
      Market_Address,
      Oracle_Address } = useConstant[chainId]
    this.web3 = new Web3(provider)
    this.Token_ADDRESS = Token_ADDRESS
    this.FundProvider_Address = FundProvider_Address
    this.USDTToken_Address = USDTToken_Address
    this.MTSToken_Address = MTSToken_Address
    this.WorldCupCard_Address = WorldCupCard_Address
    this.BlindBox_Address = BlindBox_Address
    this.Market_Address = Market_Address
    this.Oracle_Address = Oracle_Address
    this.fromWei = fromWei
    this.constant = {
      CardContract: new this.web3.eth.Contract(erc20_ABI, Token_ADDRESS),
      FundProviderContract: new this.web3.eth.Contract(FundProvider_ABI, FundProvider_Address),
      USDTTokenContract: new this.web3.eth.Contract(USDTToken_ABI, USDTToken_Address),
      MTSTokenContract: new this.web3.eth.Contract(MTSToken_ABI, MTSToken_Address),
      MarketContract: new this.web3.eth.Contract(Market_ABI, Market_Address),
      BlindBoxContract: new this.web3.eth.Contract(BlindBox_ABI, BlindBox_Address),
      OracleContract: new this.web3.eth.Contract(Oracle_ABI, Oracle_Address),
      WorldCupCardContract: new this.web3.eth.Contract(WorldCupCard_ABI, WorldCupCard_Address),
    }
  }
}
