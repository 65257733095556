import { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { useUSDTTokenHooks } from '@/hooks/useUSDTTokenHooks'
import { fromWei } from '@/utils'
import { useState, useEffect, useRef } from 'react'
import { NumberRollDiv } from './styled'

export default function NumberRoll() {
  const dataInit: ConstantInitTypes = useDataHooks()
  const { constant, Market_Address } = dataInit
  const [listAll, setListAll] = useState([[0], [0], [0], [0], [0], [0], [0], [0], [0]])
  const [targetNumber, setTargetNumber] = useState(0)
  const [currentKey, setCurrentKey] = useState(0)
  const [preNumber, setPrevNumber] = useState(0)
  const [nextNumber, setNextNumber] = useState(0)
  // const [getJackpot, setGetJackpot] = useState(0)

  useEffect(() => {
    // const timer = setInterval(() => {
    //   //赋值滚动
    //   setTargetNumber((state) => state + 1235)
    // }, 1000 * 4)
    // return () => {
    //   clearInterval(timer)
    // }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      getJine()
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const getJine = async () => {
    const jackpot = await constant.MarketContract.methods.jackpot().call() // 奖池总额
    // console.log('奖池总额:', jackpot,fromWei(jackpot))
    if (jackpot) {
      setTargetNumber(Number(fromWei(jackpot)))
    }
  }

  useEffect(() => {
    setPrevNumber(nextNumber)
    setNextNumber(targetNumber)
  }, [targetNumber])
  useEffect(() => {
    const prev = addZero(preNumber, 9)
    const next = addZero(nextNumber, 9)
    getData(prev, next)
  }, [preNumber, nextNumber])

  // 数字补零
  function addZero(num: number, n: number) {
    return num.toString().padStart(n, '0')
  }

  // 对比数据前后变化
  function getData(prev: string, next: string) {
    const currentListAll = []
    let prevArray = prev.toString().split('')
    let nextArray = next.toString().split('')
    let listInit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    for (let i = 0; i < prevArray.length; i++) {
      let prevNumber = parseInt(prevArray[i])
      let nextNumber = parseInt(nextArray[i])
      let start = -1
      let end = -1

      for (let j = 0; j < listInit.length; j++) {
        if (listInit[j] === prevNumber) {
          start = j
        }
        if (start !== -1 && listInit[j] === nextNumber) {
          end = j
          break
        }
      }
      currentListAll.push(listInit.slice(start, end + 1))
    }
    setListAll(currentListAll)
    setCurrentKey((state) => state + 1)
  }

  return (
    <NumberRollDiv>
      {/* 数字滚动 */}
      <div className="box-number">
        {listAll.map((list, i) => {
          return (
            <div key={i} className={'list-wrap' + (i == 2 || i == 5 ? ' dh' : '')}>
              <div className={`roll roll_${list.length - 1}`} key={currentKey}>
                {list.map((item, index) => {
                  return <div key={currentKey + '_' + index}>{item}</div>
                })}
              </div>
            </div>
          )
        })}
      </div>
    </NumberRollDiv>
  )
}
