import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import Web3 from 'web3'

const web3 = new Web3(Web3.givenProvider)

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  NetWork = 'NetWork',
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

export const RPC_URLS: { [chainId: number]: string } = {
  // 1: 'https://mainnet.infura.io/v3/84842078b09946638c03157f83405213',
  // 43114: 'https://api.avax.network/ext/bc/C/rpc',
  56: 'https://bsc-dataseed1.binance.org',
  // 1337: 'http://47.108.77.85:8545'
}
// 1, 43114, 56, 1337

export const injected = new InjectedConnector({ supportedChainIds: [56] })

export const walletconnect = new WalletConnectConnector({
  // , 1: RPC_URLS[1], 43114: RPC_URLS[43114], 1337: RPC_URLS[1337]
  rpc: { 56: RPC_URLS[56] },
  qrcode: true,
  supportedChainIds: [56],
  // , 1, 43114, 1337
  // chainId: 56,
})

export const network = new NetworkConnector({
  // , 1: RPC_URLS[1], 43114: RPC_URLS[43114], 1337: RPC_URLS[1337]
  urls: { 56: RPC_URLS[56] },
  defaultChainId: 56,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.NetWork]: network,
}

export const defaultChainId = 56

export const useConstant = {
  56: {
    CHAIN_ID: 56,
    RPC_URL: 'https://bsc-dataseed1.binance.org',
    Token_ADDRESS: '0x0000000000000000000000000000000000000000',
    FundProvider_Address: '0x900e15E8a43c6Cd7A4dBCeDCbE667Ee8b03d4948',
    USDTToken_Address: '0x55d398326f99059fF775485246999027B3197955',
    MTSToken_Address: '0xA14a11F218f17ccd9F2Cd4226e416bFd96187eCc',

    WorldCupCard_Address: '0x47bB52366aEE7a5dBf3b2f9Cc9441cdB0fC5BC4D',
    BlindBox_Address: '0x6dC9eBF1Df0F016313f35c8F6fE6D901fFBFB009',
    Market_Address: '0xD616d8ab1447866FcBD5ED252aBE582ffbF2977c',
    Oracle_Address: '0x7D4A697328B97B8DEd0c3b88aed8aecC4a0ecd2E',
    fromWei: 'ether',
  }
}

export const netWorks = {
  56: {
    chainId: web3.utils.numberToHex(56),
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'bnb',
      symbol: 'bnb',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  }
}
