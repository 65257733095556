import styled from 'styled-components'
import outPic from '@/assets/Img/outPic.png'
import boxBtn from '@/assets/Img/Receive.png'
import hBtn from '@/assets/Img/min.png'
import Succee from '@/assets/Img/boxBtn.png'
import number_bg from '@/assets/Img/number-bg.png'
import cardbj from '@/assets/Img/cardbj.png'

export const SoldOutWral = styled.div`
  width: 100%;
  margin: 138px auto;
  .soldStyle {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    font-style: italic;
    img {
      cursor: pointer;
    }
    .soldout {
      font-size: 20px;
      font-family: AvenirNext-HeavyItalic;
      font-weight: normal;
      font-style: italic;
    }
    .numstyle {
      display: flex;
      font-size: 40px;
      font-family: BerthaBold;
      font-weight: 400;
      font-style: italic;
      line-height: 40px;
    }
    .boxGou {
      margin-top: 36px;
      > img {
        position: relative;
        width: 285px;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .copyData {
      z-index: 100;
      margin-top: 46px;
      font-size: 24px;
      .copyDataShow {
        display: flex;
        width: 520px;
        height: 50px;
        margin: 10px 0;
        border: 2px solid #00e2ff;
        border-top-left-radius: 92.8px;
        border-bottom-right-radius: 92.8px;
        background: rgba(0, 50, 132, 0.8);
        padding: 5px 20px;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 600;
        > img {
          position: relative;
          width: 103px;
        }
        span {
          width: 360px;
          overflow: hidden; /* 超出一行文字自动隐藏 */
          text-overflow: ellipsis; /*文字隐藏后添加省略号*/
          white-space: nowrap; /*强制不换行*/
        }
      }
    }
    .total {
      width: 100%;
      height: 974px;
      background-image: url('${outPic}');
      background-size: 100% 100%;
      .title {
        position: relative;
        margin-top: 115px;
        img {
          width: 696px;
          height: 54px;
          margin: auto;
          display: flex;
        }
      }
      .guan-icon {
        position: relative;
        margin-top: 25px;
        img {
          margin: auto;
          height: 209px;
          display: flex;
          cursor: context-menu;
        }
      }
      .receiveStyle {
        text-align: center;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        .JinBbtn {
          cursor: pointer;
          width: 281px;
          height: 78px;
          text-align: center;
          line-height: 68px;
          font-size: 28px;
          display: flex;
          justify-content: center;
          font-family: AvenirNext-HeavyItalic;
          background-image: url('${boxBtn}');
          background-size: 100% 100%;
        }
        .timeText {
          font-size: 20px;
          font-family: AvenirNext;
          font-weight: normal;
          font-style: italic;
          color: #ffffff;
          opacity: 0.56;
        }
        .BtnClaim {
          color: #ffffff;
          font-weight: normal;
          font-size: 28px;
          font-family: AvenirNext-HeavyItalic;
          display: flex;
          .theSum {
            font-family: AvenirNext-HeavyItalic;
            font-weight: normal;
            font-style: italic;
            color: #00dbf9;
          }
        }
      }
    }

    .number-bg {
      width: 58px;
      height: 58px;
      text-align: center;
      margin-top: 90px !important;
      margin: auto;
      background-image: url('${number_bg}');
      background-size: 100% 100%;
      span {
        font-size: 56px;
        line-height: 58px;
        text-align: center;
        font-weight: bold;
        font-family: AvenirNext-HeavyItalic;
        background: linear-gradient(0deg, #0bfcff 10%, #ffffff 70%) !important;
        -webkit-background-clip: text !important;
        color: transparent !important;
      }
    }
    // style部分
    .account-balance {
      display: flex;
      justify-content: center;
      margin-top: 80px;
      font-size: 56px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;
      color: #0bfcff;
      font-family: AvenirNext-HeavyItalic;
      background: linear-gradient(0deg, #0bfcff 10%, #ffffff 70%) !important;
      -webkit-background-clip: text !important;
      color: transparent !important;
    }
  }

  @media (max-width: 768px) {
    background-color: unset;
    margin-top: 400px;
    .soldStyle {
      .boxGou {
        font-size: 0px;
        margin-top: 100px;
        > img {
          position: relative;
          width: 570px;
        }
      }
      .copyData {
        background-color: unset;
        font-size: 64px;
        .copyDataShow {
          background-color: unset;
          width: 100%;
          height: 100px;
          > img {
            position: relative;
            width: 206px;
          }
          span {
            width: 860px;
            overflow: hidden; /* 超出一行文字自动隐藏 */
            text-overflow: ellipsis; /*文字隐藏后添加省略号*/
            white-space: nowrap; /*强制不换行*/
          }
        }
      }
      .total {
        width: 100%;
        height: 2000px;
        background-image: url('${outPic}');
        background-size: auto 100%;
        background-position: center;
        .title {
          /* margin-top: 90px; */
          img {
            margin: auto;
            width: 1392px;
            height: 106px;
            display: flex;
          }
        }
        .guan-icon {
          position: relative;
          margin-top: 30px;
          margin-bottom: 400px;
          img {
            margin: auto;
            display: flex;
            height: 700px;
          }
        }
        .receiveStyle {
          text-align: center;
          margin-top: 120px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          align-items: center;
          .JinBbtn {
            cursor: pointer;
            width: 858px;
            height: 249px;
            text-align: center;
            line-height: 204px;
            font-size: 120px;
            display: flex;
            justify-content: center;
            font-family: AvenirNext-HeavyItalic;
            background-image: url('${boxBtn}');
            background-size: 100% 100%;
          }
          .timeText {
            font-family: AvenirNext;
            font-weight: normal;
            font-style: italic;
            color: #ffffff;
            opacity: 0.56;
            font-size: 80px;
          }
          .BtnClaim {
            color: #ffffff;
            font-weight: normal;
            font-size: 80px;
            font-family: AvenirNext-HeavyItalic;
            display: flex;
            .theSum {
              font-family: AvenirNext-HeavyItalic;
              font-weight: normal;
              font-style: italic;
              color: #00dbf9;
            }
          }
        }
      }
    }
  }
`

export const BlindBoxWrarl = styled.div`
  /* padding: 30px; */
  title {
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
  }
  .topClose {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    img {
      cursor: pointer;
    }
  }
  .boxstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .flag {
      background-image: url('${cardbj}');
      background-size: 100% 100%;
      width: 420px;
      height: 420px;
      video {
        width: 80%;
        margin-top: 24px;
      }
    }
    .inputtotal {
      /* width: 300px; */
      height: 80px;
      .total {
        font-family: AvenirNext-Medium;
        font-size: 24px;
        color: #fff;
        font-weight: 600;
        margin-top: 20px;
        .bule {
          color: #00e2ff;
        }
      }
      .Btnjia {
        text-align: center;
        img {
          cursor: pointer;
        }
        .jian {
          position: relative;
          left: 20px;
          bottom: 2px;
        }
        .jia {
          position: relative;
          right: 20px;
          bottom: 2px;
        }
        .input {
          width: 120px;
          height: 35px;
          background: #003284;
          border: 1px solid #00e2ff;
          position: inherit;
          text-align: center;
          font-family: AvenirNext-Medium;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
  .boxBomImg{
    width: 400px;
  }
  @media (max-width: 768px) {
    .topClose {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      img {
        cursor: pointer;
        width: 80px;
        height: 80px;
      }
    }
    .boxstyle {
      /* height: 320px; */
      /* display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center; */
      .flag {
        background-image: url('${cardbj}');
        background-size: 100% 100%;
        width: 720px;
        height: 720px;
        video {
          width: 80%;
          margin-top: 24px;
        }
      }
      .inputtotal {
        height: 80px;
        .total {
          font-family: AvenirNext-Medium;
          font-size: 24px;
          color: #fff;
          font-weight: 600;
          margin-top: 50px;
          .bule {
            color: #00e2ff;
          }
        }
        .Btnjia {
          /* margin-top: 50PX; */
          text-align: center;
          img {
            cursor: pointer;
          }
          .jian {
            width: 180px;
            position: relative;
            left: 20px;
            bottom: 2px;
          }
          .jia {
            width: 180px;
            position: relative;
            right: 20px;
            bottom: 2px;
          }
          .input {
            width: 200px;
            height: 90px;
            background: #003284;
            border: 1px solid #00e2ff;
            position: inherit;
            text-align: center;
            font-family: AvenirNext-Medium;
            font-size: 20px;
            color: #fff;
          }
        }
      }
      .boxBomImg{
        position: relative;
        width: 700px;
        margin-top: -150px;
      }
    }
  }
`

export const BtnSucceed = styled.div`
  cursor: pointer;
  width: 281px;
  height: 78px;
  line-height: 68px;
  text-align: center;
  background-image: url('${hBtn}');
  background-size: 100% 100%;
  .textPayment{
    /* width: 281px; */
    font-style: italic;
    height: 78px;
    font-size: 32px;
    font-family: AvenirNextBold;
    font-weight: bold;
    color: #221849;
    text-shadow: 2px 5px 3px #be7505;
    -webkit-text-stroke: 1px #ffffff;
    text-stroke: 2px #ffffff;
  }
  @media (max-width: 768px) {
    text-align: center;
    width: 650px;
    height: 200px;
    line-height: 180px;
    .textPayment{
      width: 110px;
      font-size: 16px;
      font-style: italic;
      font-family: AvenirNextBold;
      font-weight: bold;
      color: #221849;
      text-shadow: 2px 5px 3px #be7505;
      -webkit-text-stroke: 1px #ffffff;
      text-stroke: 2px #ffffff;
    }
  }
`

export const BtnText = styled.div`
  font-size: 32px;
  font-style: italic;
  font-family: AvenirNextBold;
  font-weight: bold;
  color: #221849;
  text-shadow: 2px 5px 3px #be7505;
  -webkit-text-stroke: 1px #ffffff;
  text-stroke: 2px #ffffff;
  @media (max-width: 768px) {
    font-size: 32px;
    font-style: italic;
    font-family: AvenirNextBold;
    font-weight: bold;
    color: #221849;
    text-shadow: 2px 5px 3px #be7505;
    -webkit-text-stroke: 1px #ffffff;
    text-stroke: 2px #ffffff;
  }
`
export const SucWrarl = styled.div`
  height: 600px;
  .sucBox {
    width: 100%;
    height: 480px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    .flag {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      background-image: url('${cardbj}');
      background-size: 100% 100%;
      width: 254px;
      height: 254px;
      video {
        width: 80%;
        margin-top: 17px;
      }
      .tipsNUm {
        margin: auto;
        color: #fff;
        font-size: 32px;
        font-style: italic;
        font-family: AvenirNextBold;
        font-weight: bold;
      }
    }
  }
  .Btnbottom {
    height: 60px;
    margin: 50px auto 30px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    .Succee {
      cursor: pointer;
      width: 281px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      background-image: url('${Succee}'); //
      background-size: 100% 100%;
      .btntext {
        font-size: 32px;
        font-style: italic;
        font-family: AvenirNextBold;
        font-weight: bold;
        color: #221849;
        text-shadow: 2px 5px 3px #be7505;
        -webkit-text-stroke: 1px #ffffff;
        text-stroke: 2px #ffffff;
      }
    }
    .Open {
      cursor: pointer;
      width: 281px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      background-image: url('${hBtn}');
      background-size: 100% 100%;
      .btntext {
        font-size: 32px;
        font-style: italic;
        font-family: AvenirNextBold;
        font-weight: bold;
        color: #221849;
        text-shadow: 2px 5px 3px #be7505;
        -webkit-text-stroke: 1px #ffffff;
        text-stroke: 2px #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    height: 300px;
    .sucBox {
      width: 100%;
      height: 1100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      .flag {
        background-image: url('${cardbj}');
        background-size: 100% 100%;
        width: 350px;
        height: 350px;
        video {
          width: 80%;
          margin-top: 17px;
        }
        .tipsNUm {
          margin: auto;
          color: #fff;
          font-size: 32px;
          font-style: italic;
          font-family: AvenirNextBold;
          font-weight: bold;
        }
      }
    }
    .Btnbottom {
      height: 180px;
      margin: 50px auto 30px;
      display: flex;
      /* align-items: center; */
      justify-content: center;
      .Succee {
        cursor: pointer;
        width: 560px;
        height: 210px;
        line-height: 210px;
        text-align: center;
        background-image: url('${Succee}'); //
        background-size: 100% 100%;
        .btntext {
          font-size: 24px;
          font-style: italic;
          font-family: AvenirNextBold;
          font-weight: bold;
          color: #221849;
          text-shadow: 2px 5px 3px #be7505;
          -webkit-text-stroke: 1px #ffffff;
          text-stroke: 2px #ffffff;
        }
      }
      .Open {
        cursor: pointer;
        width: 560px;
        height: 210px;
        line-height: 210px;
        text-align: center;
        background-image: url('${hBtn}');
        background-size: 100% 100%;
        .btntext {
          font-size: 24px;
          font-style: italic;
          font-family: AvenirNextBold;
          font-weight: bold;
          color: #221849;
          text-shadow: 2px 5px 3px #be7505;
          -webkit-text-stroke: 1px #ffffff;
          text-stroke: 2px #ffffff;
        }
      }
    }
  }
`
export const TokenWral = styled.div`
  /* width: 100%; */
  .title-top-style{
    width: 100%;
    height: 90px;
    background: #00DEFF;
    border-radius: 1px 1px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      margin-left: 20px;
      font-size: 32px;
      font-family: AvenirNext-HeavyItalic;
      font-weight: 400;
      font-style: italic;
      color: #FFFFFF;
      text-shadow: 0px 4px 4px #1B0843;
      -webkit-text-stroke: 2px #1B0743;
      text-stroke: 2px #1B0743;
    }
    img{
      width: 34px;
      height: 34px;
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .conent-style{
    height: auto;
    color: #FFFFFF;
    padding: 20px 100px;
    .input{
      background: #003284;
      border-radius: 0px;
      border: 1px solid #00e2ff;
      font-size: 20px;
      color: #fff;
    }
    .inputNumber{
      background: #003284;
      border-radius: 0px;
      border: 1px solid #00e2ff;
      padding: 0.02083rem 0.05729rem;
      font-size: 20px;
      color: #fff;
      width: 100%;
    }
    .ant-radio-wrapper{
      margin-right: 0.24167rem
    }
    .ant-radio-inner {
        width: 0.08333rem;
        height: 0.08333rem;
      }
      .ant-radio-inner::after {
      width: 0.09rem;
      height: 0.09rem;
      }
  }
  @media (max-width: 768px) {
    /* width: 100%; */
    .title-top-style{
      width: 100%;
      height: 140px;
      background: #00DEFF;
      border-radius: 1px 1px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title{
        margin-left: 20px;
        font-size: 32px;
        font-family: AvenirNext-HeavyItalic;
        font-weight: 400;
        font-style: italic;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px #1B0843;
        -webkit-text-stroke: 2px #1B0743;
        text-stroke: 2px #1B0743;
      }
      img{
        width: 34px;
        height: 34px;
        cursor: pointer;
        margin-right: 20px;
      }
    }
    .conent-style{
      width: 100%;
      height: auto;
      color: #FFFFFF;
      padding: 20px 100px;
      .input{
        background: #003284;
        border-radius: 0px;
        border: 1px solid #00e2ff;
        font-size: 20px;
        color: #fff;
      }
      .ant-radio-wrapper{
        margin-right: 160px;
      }
      .ant-radio-inner {
        width: 0.28333rem;
        height: 0.28333rem;
      }
      .ant-radio-inner::after {
      width: 0.11rem;
      height: 0.11rem;
      }
    }
  }
`
