import styled from 'styled-components'
import Wei from '@/assets/Img/Wei.png'
import Xuan from '@/assets/Img/Xuan.png'

export const CarouselWral = styled.div`
  width: 1330px;
  margin: 160px auto 0;
  .carousel {
    height: 30.20833vw;
    .BannerStyle {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 50px 0;
      > img {
        position: relative;
        width: 189px;
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -110px;
      > li {
        background-image: url('${Wei}');
        background-size: 100% 100%;
        background-color: unset;
        width: 96px;
        > button {
          background-color: unset;
          width: 96px;
        }
        &.slick-active {
          background-image: url('${Xuan}');
          width: 96px;
          > button {
            background: unset;
            width: 96px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    position: relative;
    width: 1800px;
    margin-top: 300px;
    .carousel {
      height: unset;
      .BannerStyle {
        > img {
          position: relative;
          width: 320px;
        }
      }
      .slick-dots {
        position: absolute;
        bottom: -180px;
        > li {
          background-color: unset;
          width: 192px;
          > button {
            background-color: unset;
            width: 192px;
          }
          &.slick-active {
            background-image: url('${Xuan}');
            width: 192px;
            > button {
              background: unset;
              width: 192px;
            }
          }
        }
      }
    }
  }
`
