import styled from 'styled-components'
import homeBj from '@/assets/Img/back.png'
import Fifa from '@/assets/Img/FIFA.png'

export const HomeWrapper = styled.div`
  width: 100%;
  /* height: 100vh; */
  background-color: #020e3e;
  .home {
    .mainDrawing {
      background-image: url('${homeBj}');
      width: 100%;
      height: 1975px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        background-size: auto 100%;
        background-position: center;
        height: 4200px;
      }
    }
  }
  .home::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    .home {
      overflow-x: hidden;
    }
  }
`
export const FiflWprpld = styled.div`
  margin: 70px auto;
  width: 876px;
  height: 115px;
  background-image: url('${Fifa}');
  background-size: 100% 100%;
  @media (max-width: 768px) {
    position: relative;
    margin: 140px auto;
    width: 1752px;
    height: 230px;
  }
`
