import TopBar from '@/components/TopBar'
import { Button, Input, message, Modal, Spin, Tabs } from 'antd'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BlindBoxWrarl, BtnSucceed, BtnSucceeds, BtnText, ProfileWrapper } from './styled'
import Copy from '@/assets/Img/copy.png'
import boxBom from '@/assets/Img/boxBom.png'
import close from '@/assets/Img/close.png'
import Receive from '@/assets/Img/Receive.png'
import cardImg from '@/assets/Img/card.png'
import Copyto from 'copy-to-clipboard'
import { readGetPastEvents, readGetPastEventsBSC } from '@/contracts/common'
import { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import web3 from '@/contracts/initWeb3'
import { ation, Cyclereward, fromWei, getTime, getTimeY, ipConfig, transCountry } from '@/utils'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import Loading from '@/components/Loading'

import { useSelector } from 'react-redux'
import ZzLoading from '@/components/zzLoading'
import OpenLoading from '@/components/openLoading'

interface NftNum {
  id: number
  flag: any
  num: number
  showImg: boolean
  country: string
}

interface tokenPic {
  token: number
  flag: any
  country: string
}
const boxDefault = require('@/assets/Mp4/boxDefault.mp4')
export default memo(function HomePages(props: any) {
  const { t, i18n } = useTranslation()
  const dataInit: ConstantInitTypes = useDataHooks()
  const { constant, Market_Address } = dataInit
  const context = useWeb3React<Web3Provider>()
  const { account } = context
  const [getCycles, setGetCycles] = useState<string>()
  const [getCurrents, setGetCurrents] = useState<number>(0)
  const [getVolumes, setGetVolumes] = useState<string>()
  const [getRerwards, setGetRerwards] = useState<string>()
  const [getUserReward, setGetUserReward] = useState<string>()
  const [getPurchase, setGetPurchase] = useState<number>(0)
  const [boxShow, setBoxShow] = useState(false)
  // const [videoShow, setVideoShow] = useState(false)

  const [getNftList, setGetNftList] = useState<NftNum[]>([])
  const [getTokenId, setGetTokenId] = useState<tokenPic[]>([])
  const [boxData, setBoxData] = useState<number>(0)
  const [tokenId, setTokenId] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [zloading, setZLoading] = useState(false)
  const [oloading, setOLoading] = useState(false)
  const walletInfo = useSelector((state: any) => state.walletInfo)
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const userAdress = walletInfo.wallet === 'Bsc' ? myAddress : account
  const address = `${ipConfig(ation)}?address=${userAdress}`
  const [showTabIdx, setshowTabIdx] = useState<string>('1')
  useEffect(() => {
    // web3.eth.getTransaction('0xa8c996235d5ca26f64d4413c0458905ced724ad9c100d472459ab81c31112aa9')
    // .then() {

    // }.
  }, [])

  useEffect(() => {
    // console.log(props.match.params)
    if (props.match.params) {
      setshowTabIdx(props.match.params.key)
    }
    if (props.match.params.key === '3') {
      getBlindBox()
    } else if (props.match.params.key === '1') {
      getVolume()
    }
  }, [props.match.params])

  useEffect(() => {
    if (userAdress) {
      getVolume()
    }
  }, [userAdress])
  // tabs 选项
  const tabsClick = (key: string) => {
    setshowTabIdx(key)
    if (key === '1') {
      getVolume()
    } else if (key === '2') {
      getNft()
    } else if (key === '3') {
      getBlindBox()
    }
  }
  // 读取数据
  const getVolume = async () => {
    setLoading(true)
    try {
      // 某⽤户总的奖励包括历史
      let userReward = await constant.MarketContract.methods.getUserReward(userAdress).call()
      if (userReward) {
        setGetUserReward(web3.utils.fromWei(userReward, 'ether'))
      }
      // console.log('某⽤户总的奖励包括历史:', userReward, web3.utils.fromWei(userReward, 'ether'))

      // ⽤户下级购买总额接⼝
      let current = await constant.MarketContract.methods.getVolumeOfUser(userAdress).call()
      if (current) {
        setGetVolumes(web3.utils.fromWei(current, 'ether'))
      }
      // console.log('⽤户下级购买总额接⼝:', current, web3.utils.fromWei(current, 'ether'))

      let time = await constant.MarketContract.methods.getCurrentEpochTime().call()
      if (time) {
        setGetCycles(getTime(time * 1000))
      }
      // console.log('当前周期：', getTime(time * 1000), time)

      // ⽤户每周结算总分销⾦额
      let epochVolume = await constant.MarketContract.methods.getCurrentEpochVolume(userAdress).call()
      if (epochVolume) {
        setGetCurrents(Number(web3.utils.fromWei(epochVolume, 'ether')))
      }
      // console.log('⽤户每周结算总分销⾦额接⼝:', typeof epochVolume, web3.utils.fromWei(epochVolume, 'ether'))
      // console.log('Number(fromWei(epochVolume))', Number(fromWei(epochVolume)), epochVolume)
      if (Number(fromWei(epochVolume)) > 0) {
        if (Cyclereward <= Number(fromWei(epochVolume))) {
          // console.log('前端计算奖励', getPurchase)
          setGetPurchase(getCurrents * 0.05)
        }
      } else {
        setGetPurchase(0)
      }
      // console.log('前端计算奖励', getPurchase)

      // ⽤户每周结算分销奖励 getRerwards
      let rerwards = await constant.MarketContract.methods.claimableRewards(userAdress).call()
      if (rerwards) {
        setGetRerwards(web3.utils.fromWei(rerwards, 'ether'))
      }
      // console.log('⽤户每周结算分销奖励:', rerwards, web3.utils.fromWei(rerwards, 'ether'))

      setLoading(false)
    } catch {
      setLoading(false)
      message.warning('Get Exception')
    }
  }
  // 获取NFT
  const getNft = () => {
    setGetNftList([])
    setLoading(true)
    for (let i = 1; i <= 32; i++) {
      // console.log('获取NFT', getNftList)
      nftAmount(i)
    }
  }
  const sortId = (a: any, b: any) => {
    return a.id - b.id
  }
  const nftAmount = async (id: number) => {
    try {
      // throw new Error()
      let amount = await constant.WorldCupCardContract.methods.balanceOf(userAdress, id).call()
      if (id === 32) {
        setLoading(false)
      }
      if (amount != 0) {
        setGetNftList((Nftlist) => {
          Nftlist.push({
            id: id,
            flag: `https://worldcup.on.fleek.co/video/myNft/${id}.mp4`,
            num: amount,
            showImg: true,
            country: transCountry(id),
          })
          return Nftlist
        })
      }
      getNftList.sort(sortId)
    } catch {
      setLoading(false)
      message.warning({ content: 'Get Exception', key: 'Exception' })
    }
  }
  // 获取盲盒 BlindBox
  const getBlindBox = async () => {
    setLoading(true)
    try {
      let num = await constant.BlindBoxContract.methods.balanceOf(userAdress, 1).call()
      if (num > 0) {
        setBoxData(num)
      } else {
        setBoxData(0)
      }
      setLoading(false)
      // console.log('获取盲盒背包:', num)
    } catch {
      message.warning('Get Exception')
      setLoading(false)
    }
  }

  // ⽤户获得的利润 提现
  const loadClaim = async () => {
    if (Number(getRerwards) <= 0) {
      message.warning('No reward collection')
      return
    }
    setLoading(true)
    constant.MarketContract.methods
      .claim()
      .send({ from: userAdress })
      .on('transactionHash', function (hash: any) {
        // console.log('⽤户获得的利润', hash)
      })
      .on('receipt', async (receipt: any) => {
        // console.log('⽤户获得的利润', receipt)
        message.success('Reward collection succeeded')
        getVolume()
        setLoading(false)
      })
      .on('error', function (error: any, receipt: any) {
        // console.log('⽤户获得的利润', error)
        setLoading(false)
        message.error(error.message)
      })
  }

  // 复制链接
  const copyClick = () => {
    if (Copyto(address)) {
      message.success('Copy succeeded')
    } else {
      message.error('Copy failed')
    }
  }

  const BlindBoxOpen = async () => {
    if (boxData <= 0) {
      message.warning('Please open the mystery boxes after purchasing it')
      return
    }
    setLoading(true)
    // ⽤户是否请求过随机数
    let hasRequest = await constant.MarketContract.methods.hasRequest(userAdress).call()
    let isApprove = await constant.BlindBoxContract.methods.isApprovedForAll(userAdress, Market_Address).call()
    // console.log('ture⽤户是否请求过随机数', hasRequest, isApprove)
    if (hasRequest && isApprove) {
      // 1、随机数true || 授权true: openBox(),直接开启盲盒
      setLoading(false)
      setOLoading(true)
      openBox()
      // console.log('1、随机数true || 授权true: openBox(),直接开启盲盒')
    } else if (!hasRequest && !isApprove) {
      // 2、随机数false|| 授权false: requestLoad(true),随机数/授权/开盲盒
      setLoading(false)
      setZLoading(true)
      requestLoad(true)
      // console.log('2、随机数false|| 授权false: requestLoad(true),随机数/授权/开盲盒')
    } else if (hasRequest && !isApprove) {
      // 3、随机数true|| 授权false: setApproval(),授权/开盲盒
      setApproval()
      // console.log('3、随机数true|| 授权false: setApproval(),授权/开盲盒')
    } else if (!hasRequest && isApprove) {
      // 4、随机数false|| 授权true: requestLoad(false), 随机数/开盲盒
      setLoading(false)
      setZLoading(true)
      requestLoad(false)
      // console.log('4、随机数false|| 授权true: requestLoad(false), 随机数/开盲盒')
    }
  }
  // 请求随机数
  const requestLoad = async (state: boolean) => {
    console.log('userAdress', userAdress)
    if (state) {
      constant.MarketContract.methods
        .request()
        .send({ from: userAdress })
        .on('transactionHash', function (hash: any) {
          // console.log('获取随机数', hash)
        })
        .on('receipt', async (receipt: any) => {
          // console.log('获取随机数', receipt)
          setZLoading(false)
          setLoading(true)
          setApproval()
        })
        .on('error', function (error: any) {
          console.log('获取随机数', error)
          setLoading(false)
          message.error(error.message)
        })
    } else {
      constant.MarketContract.methods
        .request()
        .send({ from: userAdress })
        .on('transactionHash', function (hash: any) {
          // console.log('获取随机数', hash)
        })
        .on('receipt', async (receipt: any) => {
          // console.log('获取随机数', receipt)
          setOLoading(true)
          setZLoading(false)
          const timeRef = setTimeout(() => {
            openBox()
          }, 3000)
          //清楚延时器
          return () => {
            clearTimeout(timeRef)
          }
        })
        .on('error', function (error: any) {
          setLoading(false)
          setZLoading(false)
          setOLoading(false)
          console.log('获取随机数', error.message)
          message.error(error.message)
        })
    }
  }
  //开盲盒授权
  const setApproval = async () => {
    constant.BlindBoxContract.methods
      .setApprovalForAll(Market_Address, true)
      .send({ from: userAdress })
      .on('transactionHash', function (hash: any) {
        // console.log('开启盲盒授权', hash)
      })
      .on('receipt', async (receipt: any) => {
        // console.log('开启盲盒授权', receipt)
        setLoading(false)
        setOLoading(true)
        openBox()
      })
      .on('error', function (error: any, receipt: any) {
        // console.log('开启盲盒授权', error)
        setLoading(false)
        setZLoading(false)
        setOLoading(false)
        message.error(error.message)
      })
  }
  // 开盲盒
  const openBox = async () => {
    // setLoading(true)
    setTokenId(0)
    setGetTokenId([])
    constant.MarketContract.methods
      .open()
      .send({ from: userAdress })
      .on('transactionHash', function (hash: any) {
        // console.log('开盲盒', hash)
      })
      .on('receipt', async (receipt: any) => {
        // console.log('开盲盒', receipt)
        message.success('Open the mystery boxes successfully')
        let eventData = {
          blockNumber: receipt.blockNumber,
          transactionHash: receipt.transactionHash,
        }
        getBlindBox()
        getEvent(eventData)
      })
      .on('error', function (error: any, receipt: any) {
        // console.log('开盲盒', error)
        setLoading(false)
        setZLoading(false)
        setOLoading(false)
        message.error(error.message)
      })
  }
  // 读事件获取TokenId
  const getEvent = async (eventData: any) => {
    try {
      let transferSource: any = await readGetPastEvents(constant.WorldCupCardContract, 'TransferSingle', eventData.blockNumber)
      // console.log('TransferSingle事件：', transferSource)
      let data = transferSource.data
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        if (element.transactionHash === eventData.transactionHash) {
          let token = element.returnValues.id
          setTokenId(token)
          setGetTokenId((tokenData) => {
            tokenData.push({
              token: token,
              flag: `https://worldcup.on.fleek.co/video/link/${token}.mp4`,
              country: transCountry(token),
            })
            return tokenData
          })
          setBoxShow(true)
          setLoading(false)
          setZLoading(false)
          setOLoading(false)
          // console.log('事件返回：', token, tokenId, getTokenId)
        }
      }
    } catch (error) {
      setLoading(false)
      setOLoading(false)
      // console.log('error', error)
    }
  }
  const videoPlay = (id: number) => {
    // console.log('移入')
    let video: any = document.getElementById('video' + id)
    const img: HTMLElement = video.previousElementSibling
    img.style.display = 'none'
    // console.log('video', video)
    if (video) {
      video.play()
    }
  }
  const videoPause = (id: number) => {
    let video: any = document.getElementById('video' + id)
    const img: HTMLElement = video.previousElementSibling
    img.style.display = 'block'
    if (video) {
      video.pause()
      video.currentTime = 0
    }
  }
  const videoShow = (id: string) => {
    let value = {
      footer: null,
      centered: true,
      icon: null,
      width: 'unset',
      className: 'videoShow',
      content: <video src={id} autoPlay loop muted={true} preload="auto" />,
      maskClosable: true,
    }
    Modal.info(value)
  }
  return (
    <ProfileWrapper>
      <div className="topbar">
        <TopBar haveId={'---'} />
      </div>
      <div className="tabs">
        <Tabs activeKey={showTabIdx} onChange={tabsClick}>
          <Tabs.TabPane tab="INCOME" key="1">
            <div className="income">
              <div className="topcome">
                <div className="textcome">
                  <div className="zm">GROSS PROFIT</div>
                  <div className="num">${getUserReward}</div>
                </div>
                <div className="textcome">
                  <div className="zm">INVITEE REVENUE</div>
                  <div className="num">${Number(getVolumes) / 10}</div>
                </div>
              </div>

              <div className="text-total">
                {/* //getPurchase  */}
                <div>
                  Total invitee revenue amount {getCycles}
                  <span className="colorBule">${getCurrents}</span>
                </div>
                <div>
                  An additional<span className="colorBule">5%</span>rebate will be provided for the purchase of
                  <span className="colorBule">${getCurrents * 0.05}</span>
                </div>
                <div className="get-rerwards">
                  <div className='textrer'>
                    Amount currently available:
                    <span className="colorBule">${getRerwards}</span>
                  </div>
                  <img src={Receive} alt="" className="BtnClaim" onClick={loadClaim} />
                </div>
                <div className="text-1">
                  Recommend someone to buy WC22 Qatar Mystery Boxes to earn<span className="colorBule">10% rebates</span>
                  straightaway, plus a
                  <span className="colorBule">5% incentive</span>
                  on the combined weekly revenue of your invitee if it reaches or exceeeds the weekly target of $2,000.
                </div>
              </div>
              <div className="copypic">
                <div className="copyAddress">{address}</div>
                <img src={Copy} alt="" onClick={copyClick} />
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="MY NFTS" key="2">
            <div className="mynft">
              {getNftList.length <= 0 ? (
                <div className="nodata">No data</div>
              ) : (
                getNftList.map((el) => {
                  return (
                    <div
                      key={el.id}
                      className="nftStyle"
                      onMouseEnter={() => videoPlay(el.id)}
                      onMouseLeave={() => videoPause(el.id)}
                      onClick={() => videoShow(el.flag)}
                    >
                      <div className="flag">
                        {/* controls 进度条 autoPlay loop muted={true}*/}
                        <img id={`video_img_${el.id}`} src={require(`@/assets/NFT/${el.id}.png`)} alt="" className="flag-img" />
                        <video src={el.flag} id={`video${el.id}`} loop preload="auto" webkit-playsInline={true} playsInline={true} />
                        <div className="boxData">×{el.num}</div>
                      </div>
                      <div className="text">{el.country}</div>
                    </div>
                  )
                })
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="MYSTERT BOXES" key="3">
            <div className="mystert">
              <BlindBoxWrarl>
                <div className="boxstyle">
                  <div className="boxText">
                    You now have <span className="bule">{boxData}</span> mystery boxes ready to open, please click the button below to open
                    them
                  </div>
                  <div className="flag">
                    <img src="" alt="" />
                    {/*  */}
                    <video src={boxDefault} autoPlay muted={true} loop preload="auto" poster={cardImg} webkit-playsInline={true} playsInline={true}></video>
                    {boxData <= 0 ? <></> : <div className="boxData">×{boxData}</div>}
                  </div>
                  <img src={boxBom} alt="" />
                  <BtnSucceed onClick={BlindBoxOpen}>
                    <BtnText>OPEN</BtnText>
                  </BtnSucceed>
                </div>
              </BlindBoxWrarl>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {loading && <Loading />}
      {zloading && <ZzLoading />}
      {oloading && <OpenLoading />}
      <Modal
        visible={boxShow}
        footer={null}
        onCancel={() => setBoxShow(false)}
        width="80%"
        className="blindBoxModal"
        centered
        closable={false}
      >
        <BlindBoxWrarl>
          <div className="topClose">
            <img src={close} alt="" onClick={() => setBoxShow(false)} />
          </div>
          <div className="tokenstyle">
            {getTokenId.length <= 0 ? (
              <div></div>
            ) : (
              getTokenId.map((el) => {
                return (
                  <div className="flag" key={el.token}>
                    <video src={el.flag} autoPlay muted={true} loop preload="auto" poster={cardImg} webkit-playsInline={true} playsInline={true}></video>
                  </div>
                )
              })
            )}
            <img src={boxBom} alt="" className="boxBom" />
            {/* onClick={BlindBoxConnect} */}
            <BtnSucceeds onClick={() => setBoxShow(false)}>
              <BtnText>Succeed</BtnText>
            </BtnSucceeds>
          </div>
        </BlindBoxWrarl>
      </Modal>
    </ProfileWrapper>
  )
})
