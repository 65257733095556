import erc20 from './abis/erc20.json'
import FundProvider from './abis/FundProvider.json'
import USDTToken from './abis/Tether.json'
import MTSToken from './abis/MTSToken.json'
import Market from './abis/Market.json'
import Oracle from './abis/Oracle.json'
import WorldCupCard from './abis/WorldCupCard.json'
import BlindBox from './abis/BlindBox.json'
import * as prdConstants from './constant.prd'
import * as uatConstants from './constant.uat'
import * as devConstants from './constant.dev'
import { NetWorkObj } from './init'

const constants: any = {
  prd: prdConstants,
  uat: uatConstants,
  dev: devConstants,
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

const { REACT_APP_ENV = 'prd' } = process.env
export const { useConstant, RPC_URLS, injected, walletconnect, network, connectorsByName, defaultChainId, netWorks } = constants[REACT_APP_ENV]

export const netWorkInit = NetWorkObj[REACT_APP_ENV]

export const erc20_ABI: any = erc20
export const FundProvider_ABI: any = FundProvider

export const USDTToken_ABI: any = USDTToken.abi
export const MTSToken_ABI: any = MTSToken.abi
export const Market_ABI: any = Market.abi
export const BlindBox_ABI: any = BlindBox.abi
export const Oracle_ABI: any = Oracle.abi
export const WorldCupCard_ABI: any = WorldCupCard.abi

export const getActiveChainId = (arr: any, network: any) => {
  let objChainId = Object.keys(arr)
  let isTrue = objChainId.some((item) => item === network.toString())
  return isTrue
}
