import { memo } from 'react'
import { RewardWral } from './styled'
import rewaed from '@/assets/Img/rewaed.png'
import biaotou from '@/assets/Img/biaotou.png'

import { Table } from 'antd'

export default memo(function RewardPage(props: any) {
  const dataSource = [
    { key: '1', teams: 'Brazil', team: '0.0150%', pool: '$ 1,500,000.00', nft: '$ 20,000.00' },
    { key: '2', teams: 'France', team: '0.0150%', pool: '$ 1,500,000.00', nft: '$ 20,000.00' },
    { key: '3', teams: 'Germany', team: '0.0150%', pool: '$ 1,500,000.00', nft: '$ 20,000.00' },
    { key: '4', teams: 'Argentina', team: '0.0150%', pool: '$ 1,500,000.00', nft: '$ 20,000.00' },
    { key: '5', teams: 'Spain', team: '0.0150%', pool: '$ 1,500,000.00', nft: '$ 20,000.00' },
    { key: '6', teams: 'England', team: '0.025%', pool: '$ 1,500,000.00', nft: '$ 12,000.00' },
    { key: '7', teams: 'Belgium', team: '0.025%', pool: '$ 1,500,000.00', nft: '$ 12,000.00' },
    { key: '8', teams: 'Portugal', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '9', teams: 'Netherlands', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '10', teams: 'Denmark', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '11', teams: 'Croatia', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '12', teams: 'Uruguay', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '13', teams: 'Senegal', team: '2.0060%', pool: '$ 1,500,000.00', nft: '$ 149.55' },
    { key: '14', teams: 'Switzerland', team: '2.7140%', pool: '$ 1,500,000.00', nft: '$ 110.54' },
    { key: '15', teams: 'USA', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '16', teams: 'Poland', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '17', teams: 'Serbia', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '18', teams: 'Ecuador', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '19', teams: 'Mexico', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '20', teams: 'Cameroon', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '21', teams: 'Qatar', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '22', teams: 'Canada', team: '4.0059%', pool: '$ 1,500,000.00', nft: '$ 74.89' },
    { key: '23', teams: 'Wales', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '24', teams: 'Japan', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '25', teams: 'Morocco', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '26', teams: 'Saudi Arabia', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '27', teams: 'South Korea', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '28', teams: 'Australia', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '29', teams: 'Ghana', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '30', teams: 'Tunisia', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '31', teams: 'lran', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
    { key: '32', teams: 'Costa Rica', team: '5.3078%', pool: '$ 1,500,000.00', nft: '$ 56.52' },
  ]

  const columns = [
    {
      title: 'SN',
      dataIndex: 'key',
      key: 'key',
      width: 90,
      className: 'key-table',
    },
    {
      title: '5-star teams',
      dataIndex: 'teams',
      key: 'teams',
      width: 200,
      className: 'data-table',
    },
    {
      title: 'Probability of each team',
      dataIndex: 'team',
      key: 'team',
      width: 220,
      className: 'data-table',
    },
    {
      title: 'Max Total Earnings Pool (30%)',
      dataIndex: 'pool',
      key: 'pool',
      width: 368,
      className: 'data-table data-table-second',
    },
    {
      title: 'Max Champion Earnings / NFT',
      dataIndex: 'nft',
      key: 'nft',
      width: 322,
      className: 'data-table data-table-third',
    },
  ]

  return (
    <RewardWral>
      <img src={rewaed} alt="" className="img-rew" />
      {/* <div className="textstyle">
        <div>COLLECT & EARN!!!</div>
        <p>
          {' '}
          Purchase and collect beautiful NFT Collectible Cards, or trade them on the NFT Marketplaces. Each NFT Collecible Card is assigned
          to a WC22 team,some of the NFT cards are Super Super Rare ("SSR"), some are Super Rare ("SR"), while others are Rare ("R'). Earn
          attractive rewards when the Team tagged to your NFT Collectible Card becomes the Champion!
        </p>
      </div> */}
      <div className="textstyle">
        <div className='title'>COLLECT & EARN PRIZES</div>
        <p>Purchase WC22 “MyChampion” Mystery Box NFTs at USDT 10 or MTS 10 each, and stand a chance to win attractive prizes if your team became the World Champion! The 5-star teams are Super Rare (“SR”) NFTs, the 4-star teams are Rare (“R”) NFTs, while the 3-star teams are Normal (“N”) NFTs. The maximum Prize Pool for WC22 NFT Mystery Box game is $3,000,000. </p>
        <p>The “SR” NFTs are allocated the most payout from the Prize Pool, where each “SR” WC22 “MyChampion” NFT may potentially generate up to $20,000 payout if the team becomes the World Champion. </p>
        <p>To make the WC22 Qatar Mystery Box game more exciting, we will allow you to match your WC22 “MyChampion” NFT with a WC22 “MyRound” NFT later on to multiple your earnings yield. The WC22 “MyRound NFT” will be launched in December 2022.</p>
        
      </div>
      <div className="textstyle">
        <div className='title'>RECOMMEND & EARN REBATES</div>
        <p>Recommend someone to buy WC22 Qatar Mystery Boxes to earn 10% rebates straightaway, plus a 5% incentive on the combined weekly revenue of your direct downlines if it reaches or exceeeds the weekly target of $2,000.</p>
      </div>
      <div className="textstyle">
        <div className='title'>TRADE & EARN PROFITS</div>
        <p>Your WC22 ”MyChampion” and WC22 “MyRound” NFTs can become very valuable in the Secondary NFT Market as fellow gamers try to match their NFTs to multiply their earnings. You may trade your WC22 NFTs privately or at the MarketPlace.</p>
      </div>
      <div className="textstyle">
        <div className='title'>EXCHANGE & EARN GAME TOKENS</div>
        <p>WC22 NFTs that do not win any prizes will receive airdrop of game tokens to play the “BALL APE” Football Manager GameFi. The “Ball Ape” GameFi will be launched in Quarter 2 of 2023.</p>
      </div>
      <div className="tableStyle">
        <img className="table-title" src={biaotou} alt="" />
        <Table showHeader={false} className="table" dataSource={dataSource} columns={columns} pagination={false} bordered />
      </div>
    </RewardWral>
  )
})
