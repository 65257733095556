import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { css, DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { Colors } from './styled'

const MEDIA_WIDTHS = {
  screenXs: 480,
  screenSm: 576,
  screenMd: 768,
  screenLg: 992,
  screenXl: 1200,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

const white = '#FFFFFF'
const black = 'red'
const themeColor = '#7210FF'
const themeColor1 = 'red'

export function colors(darkMode: boolean): Colors {
  return {
    white,
    black,
    textColor: darkMode ? white : '#9fa1ae',
    themeColor: darkMode ? themeColor1 : themeColor,
    gray: darkMode ? '#8c8c8c' : '#8c8c8c',
    'gary-4': darkMode ? '#f0f0f0' : '#f0f0f0',
    menuColor: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(27, 29, 35, 0.3)',
    activeText: darkMode ? 'white' : '#00b20c',
    defaultColor: darkMode ? '#9fa1ae' : 'white',
    bgImg: darkMode,
    footerColor: darkMode ? '#DCDFE8' : '#E9ECF5',
    footerBgColor: darkMode
      ? 'linear-gradient(90deg, rgba(59, 82, 9, 0), rgba(69, 94, 21, 0.99), rgba(81, 113, 22, 0))'
      : 'linear-gradient(90deg, rgba(28, 27, 31, 0), rgba(28, 27, 31, 0.99), rgba(28, 27, 31, 0))',
    titleColor: darkMode ? '#313736' : '#FFFFFF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    mediaWidth: mediaWidthTemplates,

    height: document.documentElement.clientHeight || document.body.clientHeight,
  }
}

export function ThemeProvider({ children }: { children: React.ReactNode }, props: any) {
  const thmemBoolean = useSelector((state: any) => state.themeInfo.themeBoolean)
  // const isNight = sessionStorage.getItem('day')
  var darkMode = true

  // const darkMode = isNight === 'daytime' ? true : false

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}
