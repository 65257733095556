import { ConstantInitTypes } from "@/contracts/constantInit"
import { Web3Provider } from "@ethersproject/providers"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useDataHooks from "./useDataHooks"

export const useMarketHooks = () => {
    const dataInit: ConstantInitTypes = useDataHooks()
    const { constant } = dataInit
    const context = useWeb3React<Web3Provider>()
    const { account } = context;
    const walletInfo = useSelector((state: any) => state.walletInfo)
    const myAddress = useSelector((state: any) => state.userInfo.address)
    const userAdress = walletInfo.wallet === 'Bsc' ? myAddress : account;
    let [share, setShare] = useState<number>(0);

    useEffect(() => {
        if (userAdress) {
            shareCall()
        }
    }, [userAdress])

    const shareCall = async () => {
        const share = await constant.MarketContract.methods.share(userAdress).call() //领取奖励
        if (share) {
            setShare(share)
        }
    }
    return share
}
