import { memo } from "react";
import map from '@/assets/Img/map.png'
import conent from '@/assets/Img/footerconent.png'
import l8k9v from '@/assets/Img/L8K9V.png'
import twitter from '@/assets/Img/twitter.png'
import telegram from '@/assets/Img/telegram.png'
import { RoadmapWral } from "./styled";
import { bscscan, Telegram, Twitter } from "@/utils";
import useDataHooks from "@/hooks/useDataHooks";
import { ConstantInitTypes } from "@/contracts/constantInit";

export default memo(function RoadmapPages(props: any) {
    const dataInit: ConstantInitTypes = useDataHooks()
    const { WorldCupCard_Address, Market_Address } = dataInit;
    return (
        <RoadmapWral>
            <div className="footerStyle">
                {/* <img src={map} alt="" className="img-rew" /> */}
                <img src={conent} alt="" className="conent" />
                <div className="text-ter">
                    <div className="footerText">
                        <div className="title">GAME DISCLAIMER</div>
                        <div className="conentText">You must be at least 18 years of age to play the mystery box games on this site regardless of whether lucky draw over the internet is restricted or not in your location</div>
                    </div>
                    <div className="footerText">
                        <div className="title">CLARIFICATION</div>
                        <div className="conentText">UK refers to the England team only in this WC22 Qatar Mystery Box game. For avoidance of doubt, UK does not refer to nor include Wales in the context of the game.</div>
                    </div>
                    <div className="footerIcon">
                        <img src={l8k9v} alt="" onClick={() => window.open(bscscan(Market_Address))} />
                        <img src={twitter} alt="" onClick={() => window.open(Twitter)} />
                        <img src={telegram} alt="" onClick={() => window.open(Telegram)} />
                    </div>
                </div>
            </div>
        </RoadmapWral>
    )
})