import React, { memo, useEffect, useState } from 'react'
import {
  ConnectWalletWrapper,
  ModalTitle,
  WalletTitleAddress,
  StyledNavLink,
  AccountMoveWrapper,
  AccountContent,
  WalletDivCreate,
  DivTest,
  ModalWallet,
} from './styled'
import { Button, Image, Row, Col, Modal, Divider, message, Drawer } from 'antd'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useDispatch, useSelector } from 'react-redux'
import { SetActivaing } from '@/store/connector/action'
import { connectorsByName, defaultChainId, getActiveChainId, RPC_URLS, netWorkInit } from '@/contracts/constant'
import { useTranslation } from 'react-i18next'
import Loading from '@/components/Loading'
import { formatStrAddress, AdaptFontSize, Network_ChainId } from '@/utils'
import { netWorks } from '@/contracts/constant'
import { SaveIsLogin, SaveWallet, SaveNetwork } from '@/store/wallet/action'
import { walletInit } from '@/contracts/init'
import { getErrorMessage } from '@/hooks/useErrorHooks'
import { SaveAddress } from '@/store/user/action'
import { CheckCircleFilled, CloseOutlined, WalletOutlined } from '@ant-design/icons'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { useHistory, useLocation } from 'react-router-dom'
import { bsc } from '@/contracts/constant.uat'
import connect from '@/assets/Img/connect.png'
import Walletpic from '@/assets/Img/WALLET.png'
import clane from '@/assets/Img/clane.png'
import BNB_ICON from '@/assets/token/BNB.svg'
import ETH_ICON from '@/assets/token/ETH.svg'
import FINDORA_ICON from '@/assets/token/findora.svg'
import METIS_ICON from '@/assets/token/metis.svg'
import METAMASK_ICON from '@/assets/svg/metamask.svg'
import WALLET_CONNECT_ICON from '@/assets/svg/wallet-connect.svg'

interface Type {
  status?: string
}

export default memo(function ConnectWalletPage(props: Type) {
  const { REACT_APP_ENV = 'prd' } = process.env
  // @ts-ignore
  const { ethereum, BinanceChain } = window
  const location = useLocation()
  let history = useHistory()

  const { status = '' } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [onShow, setOnShow] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const context = useWeb3React<Web3Provider>()
  const { activate, active, error, library, deactivate, account } = context

  const walletInfo = useSelector((state: any) => state.walletInfo)
  const myAddress = useSelector((state: any) => state.userInfo.address)

  const [moveSwitch, setMoveSwitch] = useState(false)
  const [isNetWork, setIsNetWork] = useState<boolean>(() => getActiveChainId(RPC_URLS, walletInfo.network))
  const { windowSize } = useWindowSizeHooks()

  useEffect(() => {
    setIsNetWork(getActiveChainId(RPC_URLS, walletInfo.network))
    return setIsNetWork(getActiveChainId(RPC_URLS, walletInfo.network))
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletInfo.network])

  useEffect(() => {
    if (myAddress && walletInfo.wallet === 'Bsc') dispatch(SaveAddress(myAddress))
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAddress, walletInfo])

  useEffect(() => {
    setMoveSwitch(false)
    return setMoveSwitch(false)
  }, [active])

  useEffect(() => {
    setLoading(false)
    if (active) setOnShow(false)
    if (!active && walletInfo.wallet !== 'Bsc') {
      dispatch(SaveIsLogin(false))
      dispatch(SaveWallet('NetWork'))
      dispatch(SaveAddress(''))
      localStorage.removeItem('isLogin')
      localStorage.removeItem('wallet')
    }
    return () => {
      setLoading(false)
      setOnShow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, library])

  useEffect(() => {
    if (account && walletInfo.wallet !== 'Bsc') dispatch(SaveAddress(account))
  }, [account])

  const onConnect = async ({ src }: any) => {
    console.log('connectorsByName', connectorsByName, src)
    console.log('BinanceChain', BinanceChain)
    const currentConnector = connectorsByName[src]
    if (src === 'Injected') {
      dispatch(SetActivaing(currentConnector))
      setInjectedNetWorks(walletInfo.network)
      return false
    } else if (src === 'WalletConnect') {
      activate(connectorsByName[src], undefined, true)
        .then(() => {
          setLoading(false)
          dispatch(SaveIsLogin(true))
          dispatch(SaveWallet(src))
          localStorage.setItem('wallet', src)
          localStorage.setItem('isLogin', 'true')
          message.success({
            content: t('app.link.suceess'),
            className: 'message-global',
          })
        })
        .catch(async (error) => {
          let msg = getErrorMessage(error)
          await deactivate()
          message.error({
            content: msg,
            className: 'message-global',
          })
          setLoading(false)
          connectorsByName[src].walletConnectProvider = undefined
        })
    } else {
      setBscNetWorks()
    }
  }
  // 币安链钱包
  const setBscNetWorks = async () => {
    const binanceNetwork = (window as any).BinanceChain.chainId
    // 1、正式 bsc-mainnet 56 2、测试 bsc-testnet 97
    if (binanceNetwork !== netWorks[56].chainId) {
      try {
        // Network_ChainId
        await (window as any).BinanceChain.switchNetwork('bsc-mainnet')
        setLoading(false)
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          alert('add this chain id')
        }
      }
    } else {
      bsc
        .activate()
        .then((res: any) => {
          dispatch(SaveIsLogin(true))
          dispatch(SaveWallet('Bsc'))
          dispatch(SaveAddress(res.account))
          setLoading(false)
          localStorage.setItem('wallet', 'Bsc')
          localStorage.setItem('isLogin', 'true')
          message.success({
            content: t('app.link.suceess'),
            className: 'message-global',
          })
          setOnShow(false)
          console.log('币安链钱包', res)
        })
        .catch(async (err: any) => {
          setLoading(false)
          await bsc.deactivate()
        })
    }
  }

  const setInjectedNetWorks = (objChainId: string | number) => {
    return changeInjectedNetwork(objChainId)
      .then(async () => await activeInjectedChange())
      .catch((error) => {
        message.error({
          content: error.message,
          className: 'message-global',
        })
        setLoading(false)
      })
  }

  const changeInjectedNetwork = (objChainId: any) => {
    return new Promise(async (resolve: any, reject) => {
      // @ts-ignore
      const { ethereum } = window
      // console.log('ethereum', ethereum)
      let obj: any = isNetWork ? netWorks[objChainId] : netWorks[defaultChainId]
      if (ethereum && ethereum.isMetaMask && obj) {
        if (obj.isSwitch)
          ethereum
            .request({ method: 'wallet_switchEthereumChain', params: [{ chainId: obj.chainId }] })
            .then(() => setTimeout(resolve, 500))
            .catch((err: any) => reject(err))
        else
          ethereum
            .request({ method: 'wallet_switchEthereumChain', params: [{ chainId: obj.chainId }] })
            .then(() => setTimeout(resolve, 500))
            .catch((switchError: any) => {
              if (switchError.code === 4902)
                ethereum
                  .request({ method: 'wallet_addEthereumChain', params: [netWorks[objChainId]] })
                  .then(() => setTimeout(resolve, 500))
                  .catch((err: any) => reject(err))
              else reject(switchError)
            })
      } else resolve()
    })
  }

  const activeInjectedChange = async () => {
    activate(connectorsByName['Injected'], undefined, true)
      .then(() => {
        dispatch(SaveIsLogin(true))
        dispatch(SaveWallet('Injected'))
        setLoading(false)
        localStorage.setItem('wallet', 'Injected')
        localStorage.setItem('isLogin', 'true')
        message.success({
          content: t('app.link.suceess'),
          className: 'message-global',
        })
      })
      .catch(async (error) => {
        let msg = getErrorMessage(error)
        await deactivate()
        setLoading(false)
        message.error({
          content: msg,
          className: 'message-global',
        })
      })
  }

  /** choose Wallet */
  const switchWalletConnect = async (src: any) => {
    onConnect({ src })
    setLoading(true)
  }

  const switchNetWork = (str: any) => {
    localStorage.setItem('chainId', str)
    dispatch(SaveNetwork(str))
  }

  const loginOut = async () => {
    if (location.pathname != '/home') {
      history.replace('/home')
    }
    await bsc.deactivate()
    await deactivate()
    dispatch(SaveIsLogin(false))
    dispatch(SaveAddress(''))
    dispatch(SaveWallet('NetWork'))
    localStorage.removeItem('isLogin')
    localStorage.removeItem('wallet')
    localStorage.removeItem('currentConnector')
    message.info({
      content: t('Exit succeeded'),
      className: 'message-global',
    })
  }

  const OnMouseOverDome = () => {
    return (
      <AccountMoveWrapper>
        <AccountContent>
          <StyledNavLink to={{}} onClick={() => loginOut()}>
            {t('app.my.title3')}
          </StyledNavLink>
        </AccountContent>
      </AccountMoveWrapper>
    )
  }
  // 下拉退出
  const OnMouseOverH5Dome = () => {
    return (
      <Drawer
        key="h5"
        className="drawer-account"
        placement="bottom"
        onClose={() => setMoveSwitch(false)}
        visible={moveSwitch}
        height="20vw"
        closeIcon={<CloseOutlined style={{ color: 'black' }} />}
      >
        <div className="drawer-content">
          <StyledNavLink to={{}} onClick={() => loginOut()}>
            {t('app.my.title3')}
          </StyledNavLink>
        </div>
      </Drawer>
    )
  }
  // 选择钱包登录
  const WalletDiv = () => (
    <ModalWallet>
      <div className="title">
        <img src={Walletpic} alt="" className="Walletpic" />
        <img src={clane} alt="" className="clane" onClick={() => setOnShow(false)} />
      </div>
      <div className="conent">
        {walletInit
          .filter((item) => {
            if (!ethereum) return item.name !== 'Metamask'
            return true
          })
          .map((item, index) => (
            <div className="choose-info" key={index} onClick={() => switchWalletConnect(item.link)}>
              <Image width={50} height={50} src={item.icon} preview={false} style={{ borderRadius: '100%' }} />
              <span className="choose-span">{item.name}</span>
            </div>
          ))}
      </div>
    </ModalWallet>
  )

  return (
    <ConnectWalletWrapper className="connect-wallet">
      {walletInfo.wallet !== 'Bsc' ? (
        <>
          {active && myAddress && windowSize.innerWidth >= AdaptFontSize && (
            <WalletTitleAddress onMouseEnter={() => setMoveSwitch(true)} onMouseLeave={() => setMoveSwitch(false)}>
              <img src={METAMASK_ICON} alt="" width={25} height={25} />
              <span className="wallet-title-address-span">{formatStrAddress(6, 4, myAddress)}</span>
              <span>{moveSwitch}</span>
              {moveSwitch && <OnMouseOverDome />}
            </WalletTitleAddress>
          )}
        </>
      ) : (
        <>
          {myAddress && windowSize.innerWidth >= AdaptFontSize && (
            <WalletTitleAddress onMouseEnter={() => setMoveSwitch(true)} onMouseLeave={() => setMoveSwitch(false)}>
              <img src={BNB_ICON} alt="" width={25} height={25} />
              <span className="wallet-title-address-span">{formatStrAddress(6, 4, myAddress)}</span>
              {moveSwitch && <OnMouseOverDome />}
            </WalletTitleAddress>
          )}
        </>
      )}

      {walletInfo.wallet !== 'Bsc' ? (
        <>
          {active && myAddress && window.innerWidth < AdaptFontSize && (
            <>
              <WalletTitleAddress onClick={() => setMoveSwitch(true)}>
                <span>{formatStrAddress(6, 4, myAddress)}</span>
                <WalletOutlined />
              </WalletTitleAddress>
              <OnMouseOverH5Dome />
            </>
          )}
          {!active && status === '' && <img src={connect} alt="" className="wallet-login-btn" onClick={() => setOnShow(true)} />}
        </>
      ) : (
        <>
          {myAddress && window.innerWidth < AdaptFontSize && (
            <>
              <WalletTitleAddress onClick={() => setMoveSwitch(true)}>
                <span>{formatStrAddress(6, 4, myAddress)}</span>
                <WalletOutlined />
              </WalletTitleAddress>
              <OnMouseOverH5Dome />
            </>
          )}
          {!myAddress && status === '' && <img src={connect} alt="" className="wallet-login-btn" onClick={() => setOnShow(true)} />}
        </>
      )}

      {windowSize.innerWidth > AdaptFontSize && (
        <Modal
          visible={onShow}
          style={{ borderRadius: '0.48px' }}
          footer={null}
          onCancel={() => setOnShow(false)}
          width="1040px"
          wrapClassName="wallets"
          className="walletsModal"
          centered
          closable={false}
        >
          <WalletDiv />
          {loading && <Loading />}
        </Modal>
      )}
      {windowSize.innerWidth <= AdaptFontSize && (
        <Drawer
          key="wallet-h5"
          placement="bottom"
          onClose={() => setOnShow(false)}
          visible={onShow}
          className="drawer-mask drawer-mask-wallet"
          height="20vh"
          closeIcon={<CloseOutlined style={{ color: 'black' }} />}
        >
          {/* <Row>
            <Col span={24}>
              <ModalTitle>
                {t('app.link.modal.ftitle1')}
                <div className="span">1</div>
                <Row style={{ padding: '20px 0 0 0' }}>
                  {netWorkInit.map((item: any, index: number) => (
                    <Col span={12} className="choose-info" md={{ span: 6 }} key={index} onClick={() => switchNetWork(item.chainId)}>
                      <Image width={60} height={60} src={item.icon} preview={false} style={{ borderRadius: '100%' }} />
                      <span className="choose-span">{item.name}</span>
                      {item.chainId === walletInfo.network && (
                        <div className="choose-icon">
                          <CheckCircleFilled />
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
              </ModalTitle>
            </Col>
            <Col span={24}> */}
          <ModalTitle>
            {/* {t('app.link.modal.ftitle2')}
                <div className="span">2</div> */}
            <Row style={{ padding: '20px 0 0 0' }}>
              {walletInit
                .filter((item) => {
                  if (!ethereum) return item.name !== 'Metamask'
                  return true
                })
                .map((item, index) => (
                  <Col span={8} className="choose-info" key={index} onClick={() => switchWalletConnect(item.link)}>
                    <Image width={'20vw'} height={'20vw'} src={item.icon} preview={false} style={{ borderRadius: '100%' }} />
                    <span className="choose-span">{item.name}</span>
                  </Col>
                ))}
            </Row>
          </ModalTitle>
          {/* </Col>
            {REACT_APP_ENV === 'dev' && (
              <Col span={24}>
                <DivTest>
                  <span>*</span>
                  {t('app.link.test.tips')}
                </DivTest>
              </Col>
            )}
          </Row> */}
        </Drawer>
      )}
    </ConnectWalletWrapper>
  )
})
