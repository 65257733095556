import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

export const ConnectWalletWrapper = styled.div`
  .loginout-icon {
    cursor: pointer;
  }
  .wallet-login-btn {
    cursor: pointer;
    width: 220px;
    height: 92px;
    &:hover {
      transform: scale(1.05);
    }
  }
  @media (max-width: 768px) {
    .wallet-login-btn {
      width: 440px;
      height: 184px;
    }
  }
`

export const DivTest = styled.div`
  margin-top: 20px;
  font-size: 14.08px;
  line-height: 29.6px;
  font-weight: 400;
  color: #80808b;
  span {
    color: #ff4d4f;
    margin-right: 4px;
  }
`
export const ModalWallet = styled.div`
  height: 340px;
  .title {
    width: 100%;
    height: 80px;
    background: #00deff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 30px;
    .Walletpic {
      width: 295px;
      height: 33px;
    }
    .clane {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
  .conent {
    width: 100%;
    padding: 50px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    .choose-info {
      cursor: pointer;
      width: 30%;
      height: 150px;
      background-color: rgb(0 50 132 / 52%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 0px;
      text-indent: 0;
      :hover {
        border: 2px solid #00e2ff;
      }
      .choose-span {
        font-size: 14.08px;
        line-height: 40px;
        font-weight: 400;
        color: hsl(0deg 0% 100% / 50%);
        :hover {
          color: #fff;
        }
      }
    }
  }
`
export const ModalTitle = styled.div`
  font-size: 14.08px;
  font-weight: 600;
  position: relative;
  text-indent: 2.5em;
  .span {
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    text-indent: 0;
    text-align: center;
    border-radius: 100%;
    color: ${({ theme }) => theme.gray};
    background: ${({ theme }) => theme['gary-4']};
  }
  .choose-info {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-indent: 0;
    :hover {
      border: 1px solid ${({ theme }) => theme['gary-4']};
    }
    .choose-span {
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      color: ${({ theme }) => theme.gray};
    }
    .choose-icon {
      background: ${({ theme }) => theme.white};
    }
  }

  ${({ theme }) => theme.mediaWidth.screenMd`
    text-indent: 3.5em;
    font-size: 16px;
    line-height: 40px;
      .span{
        width: 40px;
        height: 40px;
      }
    `}
`

export const NoChainIdTips = styled.div`
  position: fixed;
  top: 88px;
  left: 0;
  width: 100%;
  z-index: 2;
  line-height: 54.08px;
  height: 54.08px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  background: ${({ theme }) => `${theme.themeColor}`};
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.screenMd`
    top: 88px;
    height: 70px;
  `}
`

export const WalletTitleAddress = styled.div`
  display: flex;
  align-items: center;
  font-size: 14.08px;
  font-weight: 400;
  padding: 5px 10px;
  border: 1px solid #00e2ff;
  border-top-left-radius: 31.68px;
  border-bottom-right-radius: 31.68px;
  background: rgba(0, 50, 132, 0.8);
  color: #fff;
  margin-right: 30.08px;
  min-width: 130px;
  cursor: pointer;
  .wallet-title-address-span {
    margin-left: 16.8px;
    margin-right: 10px;
  }
  .anticon-wallet {
    font-size: 34px;
    color: ${(props) => props.theme.themeColor};
    margin-right: 10px;
  }
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        margin-right: 14.08px;
        /* height: 54.08px; */
        border-radius: 0.316px;
        .anticon-wallet {
          font-size: 38px;
          margin-right: 0;
        }
      `,
    )}
`

const activeClassName = 'ACTIVE'
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  color: ${(props) => props.theme.gray};
  &.${activeClassName} {
    font-weight: 600;
    color: #363639 !important;
  }
  &:hover {
    content: none;
    color: #363639;
  }
`

export const AccountMoveWrapper = styled.div`
  position: absolute;
  top: 48px;
  height: auto;
  padding-top: 32px;
  text-align: center;
  z-index: 100;
`

export const AccountContent = styled.div`
  width: 143px;
  font-size: 14.08px;
  font-weight: 400;
  padding: 10px 10px;
  border: 1px solid #00e2ff;
  border-top-left-radius: 31.68px;
  border-bottom-right-radius: 31.68px;
  background: rgba(0, 50, 132, 0.8);
  color: #fff;
  margin-right: 30.08px;
  min-width: 130px;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    /* color: ${(props) => props.theme.gray}; */
    color: #fff;
    width: 100%;
    /* border-bottom: 1px solid #e5e5e5; */
    &:hover {
      color: #00e2ff;
    }
  }
`

export const WalletDivCreate = styled.div`
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(67, 68, 82, 0.18);
`
