import { useState, useEffect } from 'react'

export const useChainIdHooks = () => {
  const [chainId, setChainId] = useState<string | number>()

  const getChainId = () => {
    // @ts-ignore
    const ethereum = window && window.ethereum
    let newchainId = null
    if (ethereum && ethereum.chainId) {
      newchainId = ethereum.chainId
      if (typeof ethereum.chainId === 'string') {
        newchainId = (+ethereum.chainId).toString()
      }
    }
    return newchainId
  }

  useEffect(() => {
    const chainIdTimer = setInterval(() => {
      // @ts-ignore
      const ethereum = window && window.ethereum
      // 当用户切换网络时，MetaMask扩展会自动刷新,允许关闭 默认的网络切换自动刷新功能。
      if (ethereum) {
        ethereum.autoRefreshOnNetworkChange = false
        ethereum.on &&
          ethereum.on('chainChanged', function () {
            window.location.reload()
          })
        const newchainId = getChainId()
        if (+newchainId) {
          setChainId(+newchainId)
          clearInterval(chainIdTimer)
        }
      }
    }, 300)
    if (chainId) {
      clearInterval(chainIdTimer)
    }
    return () => clearInterval(chainIdTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  return {
    chainId,
  }
}
